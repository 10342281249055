import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { getBlogDetails } from '../../slice/ListSlice';
import { useDispatch, useSelector } from 'react-redux';

const BlogDetails = () => {
  const {id} = useParams()
  const dispatch = useDispatch();
  const blogDetails = useSelector((store) => store?.ads?.blogDetails);

  console.log("blogDetails",blogDetails)
  
  useEffect(() => {
    if (id) {
      dispatch(getBlogDetails({ id }));
    }
  }, [id]);


  return (
    <div className='w-full bg-slate-50 flex flex-col items-center'>
    <Navbar />
        <div className='py-32 lg:py-20 w-10/12'>
        <div className='text-center text-4xl font-bold text-[#234777] py-4 border-b-2 border-b-[#01C6DA]'>{blogDetails?.data?.title}</div>
        <div className="w-full mt-8">
        <div className="w-full">
            <div className='flex justify-center'>
            <div className='w-full lg:w-1/3 flex justify-center'>
                <img src={blogDetails?.data?.image} />
            </div>
            </div>
            <div className="mt-4">
            <p className="mt-4" dangerouslySetInnerHTML={{ __html: blogDetails?.data?.content }} />
            </div>
        </div>
        </div>
        </div>
        <Footer />
    </div>
  );
};

export default BlogDetails;
