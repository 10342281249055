import React from "react";
import Navbar from "../Component/Navbar";
import AffiliateAds from "../Component/AffiliateAds";
import Footer from "../Component/Footer";
import TopAds from "../Component/TopAds";
import BottomAds from "../Component/BottomAds";

function PromotedAdsPage() {
  return (
    <div className="">
      <Navbar />
      <TopAds />
      <AffiliateAds />
      <BottomAds />
      <Footer />
    </div>
  );
}

export default PromotedAdsPage;
