import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : {
      signIn: (formdata) => {
        return Api.post("v1/auth/login", formdata);
      },
      signUp: (formdata) => {
        return Api.post("v1/auth/register", formdata);
      },
      logOut: () => {
        return Api.get("v1/auth/logout");
      },
      forgotPassword: (payload) => {
        return Api.post("/v1/auth/forgot-password", payload);
      },
      getUserDetails: () => {
        return Api.get("v1/auth/user-profile");
      },
      // updateUserDetails: (id, payload) => {
      //   return Api.put("v1/auth/user-profile", payload);
      // },
      updateUserDetails: (id, payload) => {
        return Api.put(`v1/customer/${id}`, payload);
      },
      updateUserAvatar: (id, payload) => {
        return Api.post(`v1/customer/upload-avatar/${id}`, payload);
      },
      resetPassword: (formdata) => {
        return Api.post("v1/auth/reset-password", formdata);
      },
    };
