/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  FaBuilding,
  FaCamera,
  FaEdit,
  FaGlobe,
  FaLocationArrow,
  FaRegStar,
  FaStar,
  FaTags,
} from "react-icons/fa";
import EditBusinessStoreOverlay from "./EditBusinessStoreOverlay";
import EditBannerStoreOverlay from "./EditBannerStoreOverlay";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import {
  getBusinessStore,
  getStoreAds,
  clearAdsErrorAndMessage,
  getBusinessStoreBySlug,
} from "../slice/StoreSlice";
import { creatFavouriteAds } from "../slice/ListSlice";
import { getUserDetails } from "../slice/AuthSlice";

import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import { MdEmail, MdLocationOn, MdPerson, MdPersonPin } from "react-icons/md";
import { BiExitFullscreen, BiPhone } from "react-icons/bi";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { IoIosPin } from "react-icons/io";
import MyBanner from "./MyBanner";
import { Helmet } from "react-helmet";

function MyStoreAds() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [liked, setLiked] = useState({});

  const { slug } = useParams();
  const location = useLocation();
  const userDetails = useSelector((store) => store.auth?.userDetail?.data);

  const { businessStore, error } = useSelector((store) => store.store);
  const storeAds = useSelector((store) => store.store.storeAds);
  const [storeDetailData, setStoreDetailData] = useState(
    businessStore?.data || null
  );
  const [storeAdsData, setStoreAdsData] = useState(storeAds?.data || null);
  const [keyBanner, setKeyBanner] = useState(Math.random());

  const itemsPerPage = 16;
  const totalDataCount = storeAdsData?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // const maxLength = 100;

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };
  const init = async () => {
    try {
      if (slug || userDetails) {
        // console.log(isNaN(parseInt(slug)));
        if (slug && isNaN(parseInt(slug))) {
          dispatch(
            getBusinessStoreBySlug({
              slug: slug,
            })
          );
        } else {
          dispatch(
            getBusinessStore({
              customer_id: slug ? slug : userDetails?.customer_id,
            })
          );
        }
      } else {
        await dispatch(getUserDetails())
          .unwrap()
          .then((r) => {
            dispatch(
              getBusinessStore({
                customer_id: r.data.customer_id,
              })
            );
          });
      }
    } catch (error) {
      if (error?.message === "Unauthenticated.") {
        clearAdsErrorAndMessage();
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    setCurrentPage(1);
    init();
  }, [slug]);
  useEffect(() => {
    setKeyBanner(Math.random());
  }, [location.pathname]);
  useEffect(() => {
    if (storeDetailData?.customer_id) {
      dispatch(
        getStoreAds({
          skip: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          customer_id: storeDetailData?.customer_id ?? "",
        })
      );
    }
  }, [currentPage, storeDetailData?.customer_id]);

  useEffect(() => {
    if (error === "Unauthenticated.") {
      clearAdsErrorAndMessage();
      navigate("/Login");
    }
    setStoreDetailData(businessStore?.data);
  }, [businessStore, slug]);

  useEffect(() => {
    setStoreAdsData(storeAds?.data);
  }, [storeAds]);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const addToFavourite = (customer_id, listing_id, index) => {
    if (liked[index]) {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: false },
        })
      );
    } else {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: true },
        })
      );
    }

    setLiked((prevLiked) => ({
      ...prevLiked,
      [index]: !prevLiked[index],
    }));
  };
  const [isEditOverlayOpen, setEditOverlayOpen] = useState(false);
  const [isEditBannerOverlayOpen, setEditBannerOverlayOpen] = useState(false);

  // Handler to open the edit overlay
  const handleEditClick = () => {
    setEditOverlayOpen(true);
  };

  // Handler to close the edit overlay
  const handleEditClose = () => {
    init();
    setEditOverlayOpen(false);
    setEditBannerOverlayOpen(false);
  };

  const handleEditBannerClick = () => {
    setEditBannerOverlayOpen(true);
  };
  return (
    <>
      <Helmet>
        <title>
          {`${storeDetailData?.business_name}`} - Business Information
        </title>
        <meta
          name="description"
          content={`${storeDetailData?.business_name} Business information.`}
        />
        <meta
          name="keywords"
          content={`${storeDetailData?.business_name}, business, information`}
        />
        <meta property="og:title" content={storeDetailData?.business_name} />
        <meta
          property="og:description"
          content={`Discover the information of ${storeDetailData?.business_name}.`}
        />
        <meta property="og:image" content={storeDetailData?.business_logo} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="pt-20 pb-10 lg:pt-20 bg-white relative">
        {/* <div className="relative"> */}
        <img
          src={"/img/header-business-store.jpeg"}
          className="w-full h-[400px] object-cover"
          alt=""
        />
        {storeDetailData?.customer_id === userDetails?.customer_id && (
          <button
            onClick={handleEditBannerClick}
            className="absolute top-24 right-5 ring-2 ring-white rounded-full p-1 bg-white hover:text-blue-600"
          >
            <FaCamera />
          </button>
        )}
        {/* </div> */}

        <div className="flex justify-center">
          <div className="w-11/12 mt-[-150px]">
            <div className=" flex flex-col items-center">
              <div className="w-full lg:flex bg-gradient-to-r from-blue-700 to-cyan-300 rounded-3xl">
                {/* business header */}
                <div className="p-5 flex flex-col flex-1">
                  <div className="flex items-start justify-between w-full text-lg font-semibold">
                    <div className="flex rounded-full mbs-[-30px] mli-[-5px] border-[5px] border-be-0 h-[120px] w-[120px] mb-5">
                      <img
                        height="120"
                        width="120"
                        src={
                          storeDetailData?.business_logo
                            ? storeDetailData?.business_logo
                            : "/img/no-image-available.jpg"
                        }
                        // src="/img/noImage.png"
                        className="rounded-full object-cover bg-white"
                        alt="Company Logo"
                      />
                    </div>
                    {storeDetailData?.customer_id ===
                      userDetails?.customer_id && (
                      <button
                        onClick={handleEditClick}
                        className="bg-white border-[1px] hover:bg-gray-100 text-grey-darkest font-bold py-2 px-5 rounded-full inline-flex items-center gap-2"
                      >
                        <FaEdit />
                        <span className="text-base">
                          {storeDetailData?.id ? "Edit" : "Upgrade"}
                        </span>
                      </button>
                    )}
                  </div>

                  <div className="flex flex-col mb-4 text-white">
                    <label className="flex text-2xl mb-2" htmlFor="">
                      {storeDetailData?.business_name}
                      {/* <span className="text-base font-semibold">
                        {storeDetailData?.status === "active"
                          ? "ACTIVE"
                          : "DISABLE"}
                      </span> */}
                      <span
                        class={`rounded-md ${
                          storeDetailData?.status === "active"
                            ? "bg-green-500"
                            : "bg-slate-800"
                        } ml-5 py-0.5 px-2.5 border border-transparent text-sm text-white transition-all shadow-sm`}
                      >
                        {storeDetailData?.status === "active"
                          ? "ACTIVE"
                          : "DISABLE"}
                      </span>
                    </label>
                    <span className="flex items-center gap-2 text-gray-100">
                      <IoIosPin /> {storeDetailData?.business_address ?? ""}
                    </span>
                    {/* {storeDetailData?.customer_id ===
                      userDetails?.customer_id && (
                      <button
                        onClick={handleEditClick}
                        className="bg-white border-[1px] text-gray-900 hover:bg-gray-100 text-grey-darkest font-bold py-2 px-5 rounded-full lg:inline-flex hidden items-center gap-2"
                      >
                        <FaEdit />
                        <span className="text-base">
                          {storeDetailData?.id ? "Edit" : "Upgrade"}
                        </span>
                      </button>
                    )} */}
                  </div>
                </div>
                {/* business info */}
                <div className="p-5 flex flex-1 rounded-3xl">
                  <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 text-white">
                    <div className="flex items-center gap-5">
                      <div className="bg-white p-3 rounded-full">
                        <FaBuilding className="h-4 w-4 text-black" />
                      </div>
                      <div>
                        <div className="text-sm">Company No.</div>
                        <div className="text-smfont-semibold">
                          {storeDetailData?.business_company_no ?? ""}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-5">
                      <div className="bg-gray-100 p-3 rounded-full">
                        <FaGlobe className="h-4 w-4 text-black" />
                      </div>
                      <div>
                        <div className="text-sm">Website</div>
                        <Link
                          to="wwww.samplebusiness.com"
                          target="_blank"
                          className="text-sm font-semibold"
                        >
                          {storeDetailData?.business_website ?? ""}
                        </Link>
                      </div>
                    </div>
                    <div className="flex items-center gap-5">
                      <div className="bg-gray-100 p-3 rounded-full">
                        <MdEmail className="h-4 w-4 text-black" />
                      </div>
                      <div>
                        <div className="text-sm">Business email</div>
                        <div className="text-smfont-semibold">
                          {storeDetailData?.business_email ?? ""}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-5">
                      <div className="bg-gray-100 p-3 rounded-full">
                        <BiPhone className="h-4 w-4 text-black" />
                      </div>
                      <div>
                        <div className="text-sm">Business phone no.</div>
                        <div className="text-sm font-semibold">
                          {storeDetailData?.business_owner}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* flex flex-row gap-5 */}
              <div className="w-full grid grid-cols-1 md:grid-cols-12 gap-5">
                <div className="w-full col-span-8 pt-5">
                  {/* <div className="text-3xl font-bold py-4">Store Ads</div> */}
                  {/* <div className="flex justify-between gap-4 pt-6 lg:py-0 mb-5">
                    <button
                      className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <GrFormPreviousLink className="text-xl hover:text-white" />
                      PREVIOUS
                    </button>
                    <button
                      className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage * itemsPerPage >= totalDataCount}
                    >
                      NEXT
                      <GrFormNextLink className="text-xl hover:text-white" />
                    </button>
                  </div> */}
                  {storeAdsData?.items?.length > 0 ? (
                    <div className="bg-white rounded-3xl grid grid-cols-1 gap-4 sm:grid-cols-2 sm:justify-center md:grid-cols-2 lg:grid-cols-4 lg:justify-between ">
                      {storeAdsData?.items.map((items, index) => (
                        <div
                          key={index}
                          className="group flex flex-col w-full overflow-hidden  border border-slate-200 shadow-lg rounded-lg"
                        >
                          <Link to={`/ads-detail/${items.slug}`}>
                            <div className="h-48 flex justify-center items-center">
                              {items.images && items.images.length > 0 ? (
                                <img
                                  src={items.images[0]?.image_path}
                                  alt={items.title}
                                  onError={(e) => {
                                    e.target.src = "/img/no-image.png";
                                    e.target.style.width = "50%";
                                    e.target.style.height = "50%";
                                  }}
                                  className="h-full w-full object-cover"
                                />
                              ) : (
                                <img
                                  src="/img/no-image.png"
                                  alt="Default"
                                  className="w-1/2 object-cover"
                                />
                              )}
                            </div>
                          </Link>

                          <div className="flex flex-col flex-1 p-4">
                            <div className="flex flex-col flex-1">
                              <div className="flex items-center gap-1 text-[#999999] pb-2">
                                <div>
                                  <FaTags />
                                </div>
                                <div>{items.category.name}</div>
                              </div>
                              <div>
                                <hr className="border-1 text-[#999999]" />
                              </div>
                              <div className="text-lg text-[#232D3B] py-1">
                                <Link
                                  to={`/ads-detail/${items.slug}`}
                                  className="font-bold group-hover:text-yellow-500"
                                >
                                  {truncateString(items.title, 30)}
                                </Link>
                              </div>
                              <div className="flex items-start gap-1 pb-2 text-[#999999]">
                                <div className="pt-1">
                                  <MdLocationOn />
                                </div>
                                <div className="">{items.location.city}</div>
                              </div>
                            </div>
                            <div>
                              <hr className="border-1 text-[#999999]" />
                            </div>
                            <div className="flex justify-end align-middle font-bold text-[#232D3B] text-lg py-2">
                              {/* <div className="font-bold text-[#232D3B]  w-7/12">
                                {items.price}
                              </div> */}
                              <div className=" w-3/12 flex justify-end">
                                <div className=" border-l-2 border-slate-200">
                                  <Link
                                    to={`/ads-detail/${items.slug}`}
                                    key={index}
                                  >
                                    <button className="p-1 hover:scale-110 duration-300">
                                      <BiExitFullscreen className="w-5 h-5" />
                                    </button>
                                  </Link>
                                </div>
                                <div className=" border-l-2 border-slate-200">
                                  <button
                                    className="p-1 hover:scale-110 duration-300"
                                    onClick={() =>
                                      addToFavourite(
                                        items.customer_id,
                                        items.listing_id,
                                        index
                                      )
                                    }
                                  >
                                    {liked[index] ? (
                                      <FaStar className="text-yellow-400 w-5 h-5" />
                                    ) : (
                                      <FaRegStar className="w-5 h-5" />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Ads not Available</p>
                  )}
                  <div className="flex justify-between py-6">
                    <div className="mt-2 text-lg">
                      Showing{" "}
                      <strong>
                        {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
                      </strong>{" "}
                      of <strong>{totalDataCount}</strong> results.
                    </div>
                    <div className="flex justify-between gap-4 pt-6 lg:py-0">
                      <button
                        className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <GrFormPreviousLink className="text-xl hover:text-white" />
                        PREVIOUS
                      </button>
                      <button
                        className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage * itemsPerPage >= totalDataCount}
                      >
                        NEXT
                        <GrFormNextLink className="text-xl hover:text-white" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-4" key={keyBanner}>
                  {slug && storeDetailData?.customer_id ? (
                    <MyBanner customerId={storeDetailData.customer_id} />
                  ) : (
                    <>{!slug && <MyBanner />}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display the EditOverlay component when isEditOverlayOpen is true */}
        {isEditOverlayOpen && (
          <EditBusinessStoreOverlay
            onClose={handleEditClose}
            data={storeDetailData}
          />
        )}
        {isEditBannerOverlayOpen && (
          <EditBannerStoreOverlay
            onClose={handleEditClose}
            imageBanner={""}
            type={"business"}
          />
        )}
      </div>
    </>
  );
}

export default MyStoreAds;
