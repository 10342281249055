import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountry, getCurrency, getZone } from "../slice/CategorySlice";
import { getUserDetails, updateUserDetails } from "../slice/AuthSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AutocompleteDropdown from "./AutoCompleteDropdown";

const GeneralInformation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((store) => store.auth?.userDetail?.data);
  const catCurrency = useSelector((store) => store.categories.currency);
  const catCountry = useSelector((store) => store.categories.country);
  const catZone = useSelector((store) => store.categories.zone);
  const auth = useSelector((store) => store.auth);

  // console.log("-->>>",userDetails)

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    customer_id: "",
    first_name: "",
    last_name: "",
    phone: "",
    gender: "",
    currency_id: "",
    birthday: "",
    country_id: "",
    zone_id: "",
    city: "",
    zip: "",
    address_street: "",
    address_house: "",
    email: "",
  });
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "country_id") {
      await dispatch(getZone({ country_id: value })).unwrap();
    }
  };
  const handleSelect = (selectedItem) => {
    console.log("Selected item:", selectedItem);
    setFormData({ ...formData, zone_id: selectedItem.zone_id });
  };
  const handleSaveChanges = () => {
    setIsEditing(false);
  };

  const dropdownOptions = {
    gender: [
      {
        text: "Male",
        value: "M",
      },
      {
        text: "Female",
        value: "L",
      },
    ],
  };

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getCurrency());
    dispatch(getCountry());
    dispatch(getZone());
  }, []);
  useEffect(() => {
    if (userDetails) {
      setFormData({
        first_name: userDetails.first_name || "",
        last_name: userDetails.last_name || "",
        phone: userDetails.phone || "",
        gender: userDetails.gender || "M",
        currency_id: userDetails.currency?.currency_id || 1,
        birthday: userDetails.birthday || "",
        country_id: userDetails.location?.country_id || 0,
        zone_id: userDetails.location?.zone_id || 0,
        city: userDetails.location?.city || "",
        zip: userDetails.location?.zip || "",
        address_street: userDetails.address_street || "",
        address_house: userDetails.address_house || "",
        email: userDetails.email || "",
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      auth?.authError?.includes("Unauthenticated") ||
      auth?.authError?.message?.includes("Unauthenticated")
    ) {
      navigate("/");
    }

    return () => {};
  }, [auth]);
  const onSubmit = async () => {
    console.log(formData);
    try {
      await dispatch(
        updateUserDetails({
          id: userDetails.customer_id,
          payload: {
            ...formData,
            country_id: parseInt(formData.country_id),
            currency_id: parseInt(formData.currency_id),
            zip: parseInt(formData.zip),
          },
        })
      ).unwrap();
      toast.success("Data has been updated");
      dispatch(getUserDetails());
      setIsEditing(false);
    } catch (error) {
      setIsEditing(false);

      toast.error(error?.message || error);
    }
  };
  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-5">
        <h2 className="text-2xl font-semibold mb-4">General Information</h2>
        <div className="">
          {isEditing ? (
            <button
              onClick={onSubmit}
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md"
            >
              Save Changes
            </button>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              className="bg-green-500 text-white font-semibold py-2 px-4 rounded-md"
            >
              Edit
            </button>
          )}
        </div>
      </div>
      <form className="">
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <label className="block font-semibold">First Name:</label>
            {isEditing ? (
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.first_name}</p>
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="block font-semibold">Last Name:</label>
            {isEditing ? (
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.last_name}</p>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <label className="block font-semibold">Phone Number:</label>
            {isEditing ? (
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.phone}</p>
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="block font-semibold">Gender:</label>
            {isEditing ? (
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                {dropdownOptions.gender.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.gender}</p>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <label className="block font-semibold">Currency:</label>
            {isEditing ? (
              <select
                name="currency_id"
                value={formData.currency_id}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                {catCurrency?.data?.items &&
                  catCurrency?.data?.items.map((currency, i) => {
                    return (
                      <option key={i} value={currency.currency_id}>
                        {currency.name}
                      </option>
                    );
                  })}
              </select>
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.currency?.name}</p>
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="block font-semibold">Date of Birth:</label>
            {isEditing ? (
              <input
                type="date"
                name="birthday"
                value={formData.birthday}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.birthday}</p>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <label className="block font-semibold">Country:</label>
            {isEditing ? (
              <select
                name="country_id"
                value={formData.country_id}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                {catCountry?.data?.items &&
                  catCountry?.data?.items.map((country, i) => {
                    return (
                      <option key={i} value={country.country_id}>
                        {country.name}
                      </option>
                    );
                  })}
              </select>
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.location?.country_name}</p>
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="block font-semibold">Zone:</label>
            {isEditing ? (
              <AutocompleteDropdown
                data={catZone?.data?.items}
                selected={formData.zone_id}
                onSelect={handleSelect}
                displayKey={"name"}
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.location?.zone_name}</p>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <label className="block font-semibold">City:</label>
            {isEditing ? (
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.location?.city}</p>
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="block font-semibold">ZIP:</label>
            {isEditing ? (
              <input
                type="number"
                name="zip"
                value={formData.zip}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.location?.zip}</p>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-3">
            <label className="block font-semibold">Address Street:</label>
            {isEditing ? (
              <input
                type="text"
                name="address_street"
                value={formData.address_street}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.address_street}</p>
              </div>
            )}
          </div>
          <div className="mb-3">
            <label className="block font-semibold">Address House:</label>
            {isEditing ? (
              <input
                type="text"
                name="address_house"
                value={formData.address_house}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            ) : (
              <div className="py-2 rounded-md">
                <p>{userDetails?.address_house}</p>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default GeneralInformation;
