import React from 'react'
import Navbar from '../Component/Navbar'
import BusinessStore from '../Component/BusinessStore'
import Footer from '../Component/Footer'

function MyStore() {
  return (
    <div>
        <Navbar />
        <BusinessStore />
        <Footer />
    </div>
  )
}

export default MyStore