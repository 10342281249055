import React from 'react'
import UserAccount from '../Component/UserAcount'
import Navbar from '../Component/Navbar'
import Footer from '../Component/Footer'

const AccountPage = () => {
  return (
    <div>
       <Navbar />
       <UserAccount />
       <Footer />
    </div>
  )
}

export default AccountPage
