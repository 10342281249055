import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AffiliateServices from "../services/AffiliateServices";

const initialState = {
  loading: false,
  affiliateList: [],
  myAffiliateList: [],
  affiliateListTop: [],
  message: null,
  error: null,
};
export const getAffiliateList = createAsyncThunk(
  "aff/getAffiliateList",
  async ({ position, skip, limit }) => {
    const res = await AffiliateServices.getAffiliateList(position, skip, limit);
    return res.data;
  }
);
export const getAffiliateListTop = createAsyncThunk(
  "aff/getAffiliateListTop",
  async () => {
    const res = await AffiliateServices.getAffiliateListTop();
    return res.data;
  }
);
export const createAffiliate = createAsyncThunk(
  "aff/createAffiliate",
  async ({ formData }) => {
    const res = await AffiliateServices.createAffiliate(formData);
    return res.data;
  }
);
export const updateAffiliate = createAsyncThunk(
  "banner/updateAffiliate",
  async ({ Id, formData }) => {
    const res = await AffiliateServices.updateAffiliate(Id, formData);
    return res;
  }
);
export const deleteAffiliate = createAsyncThunk(
  "banner/deleteAffiliate",
  async (Id) => {
    const res = await AffiliateServices.deleteAffiliate(Id);
    return res;
  }
);
export const getMyAffiliate = createAsyncThunk(
  "aff/getMyAffiliate",
  async ({ skip, limit }) => {
    const res = await AffiliateServices.getMyAffiliate(skip, limit);
    return res.data;
  }
);
const handleError = (state, action) => {
  state.error = action.error.message;
};
const AffiliateSlice = createSlice({
  name: "aff",
  initialState,
  reducers: {
    redirectFalse: (state) => {
      state.redirect = false;
    },
    clearAffErrorAndMessage: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: {
    [getAffiliateList.pending]: (state) => {
      state.loading = true;
    },
    [getAffiliateList.fulfilled]: (state, action) => {
      state.affiliateList = action.payload;
      state.loading = false;
    },
    [getAffiliateList.rejected]: handleError,

    //top
    [getAffiliateListTop.pending]: (state) => {
      state.loading = true;
    },
    [getAffiliateListTop.fulfilled]: (state, action) => {
      state.affiliateListTop = action.payload;
      state.loading = false;
    },
    [getAffiliateListTop.rejected]: handleError,

    [createAffiliate.pending]: (state) => {
      state.loading = true;
    },
    [createAffiliate.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createAffiliate.rejected]: handleError,

    [updateAffiliate.pending]: (state) => {
      state.loading = true;
    },
    [updateAffiliate.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateAffiliate.rejected]: handleError,

    [deleteAffiliate.pending]: (state) => {
      state.loading = true;
    },
    [deleteAffiliate.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteAffiliate.rejected]: handleError,

    [getMyAffiliate.pending]: (state) => {
      state.loading = true;
    },
    [getMyAffiliate.fulfilled]: (state, action) => {
      state.myAffiliateList = action.payload;
      state.loading = false;
    },
    [getMyAffiliate.rejected]: handleError,
  },
});
export const { clearAffErrorAndMessage, redirectFalse } =
  AffiliateSlice.actions;
const { reducer } = AffiliateSlice;
export default reducer;
