import React from 'react'
import Navbar from '../Component/Navbar'
import Footer from '../Component/Footer'
import FavoriteAds from '../Component/FavoriteAds'

function FavoriteAdsPage() {
  return (
   <div>
      <Navbar />
      <FavoriteAds />
      <Footer/>
   </div>
  )
}

export default FavoriteAdsPage