import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : {
      getAdsList: (category, skip, limit) => {
        return Api.get(
          `v1/listing?skip=${skip}&limit=${limit}&category=${category}`
        );
      },
      getAdsListFilterApi: (
        category,
        skip,
        limit,
        currencies,
        max_price,
        min_price
      ) => {
        const url = `https://api.worldwideadverts.info/api/v1/listing?skip=${skip}&limit=${limit}&category=${category}&currencies=${currencies}&max_price=${max_price}&min_price=${min_price}`;
        return fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            return {
              status: "Success",
              message: "",
              data: data,
            };
          })
          .catch((error) => {
            throw new Error(`Fetch error: ${error.message}`);
          });
      },
      createAdsList: (data) => {
        return Api.post("v1/listing", data);
      },

      updateAds: (id, formData) => {
        return Api.put(`v1/listing/${id}`, formData);
      },
      deleteAds: (id) => {
        return Api.delete(`v1/listing/${id}`);
      },
      detailsAdsList: (slug) => {
        return Api.get(`v1/listing/${slug}`);
      },
      updateFavAdsList: (id) => {
        return Api.put(`v1/listing-favorite/${id}`);
      },
      getFeaturedAds: (skip, limit) => {
        return Api.post(`v1/listing/featured?skip=${skip}&limit=${limit}`);
      },
      getFeaturedAdsSlide: (skip, limit) => {
        return Api.post(`v1/listing/featured?skip=${skip}&limit=${limit}`);
      },
      getNewAds: (skip, limit) => {
        return Api.post(`v1/listing/new?skip=${skip}&limit=${limit}`);
      },
      getNewAdsSlide: (skip, limit) => {
        return Api.post(`v1/listing/new?skip=${skip}&limit=${limit}`);
      },
      getPromotedAds: (skip, limit) => {
        return Api.post(`v1/listing/promoted?skip=${skip}&limit=${limit}`);
      },
      getPromotedAdsSlide: (skip, limit) => {
        return Api.post(`v1/listing/promoted?skip=${skip}&limit=${limit}`);
      },
      creatFavouriteAds: (data) => {
        return Api.post("v1/listing-favorite", data);
      },
      getFavouriteAds: (skip, limit, id) => {
        return Api.get(`v1/listing-favorite?skip=${skip}&limit=${limit}`);
      },
      getFavouriteAdsDetail: (id) => {
        return Api.get(`v1/listing-favorite/${id}`);
      },
      removeFabAds: (id) => {
        return Api.delete(`v1/listing-favorite/${id}`);
      },
      getMyAds: (id, skip, limit, status) => {
        // return Api.get(`v1/listing/{1583}/my-listing?id=1583&status=active&skip=0&limit=10`)
        return Api.get(
          `v1/listing/${id}/my-listing?id=${id}&skip=${skip}&limit=${limit}&status=${status}`
        );
      },
      getClassified: () => {
        return Api.get("v1/classified");
      },
      getGlobalSearch: (searchData) => {
        return Api.post(`v1/listing/global`, searchData);
      },
      getBlog: (skip, limit) => {
        return Api.get(`v1/blog?&skip=${skip}&limit=${limit}`);
      },
      getBlogDetails: (id) => {
        return Api.get(`v1/blog/${id}`);
      },
      getMyAdsByCategory: (id, skip, limit, category) => {
        // return Api.get(`v1/listing/{1583}/my-listing?id=1583&status=active&skip=0&limit=10`)
        return Api.get(
          `v1/listing/${id}/my-listing?id=${id}&skip=${skip}&limit=${limit}`
        );
      },
    };
