import React from 'react'
import Navbar from '../Component/Navbar'
import FundingAds from '../Component/FundingAds'
import Footer from '../Component/Footer'

function FundingAdsPage() {
  return (
    <div>
        <Navbar />
        <FundingAds />
        <Footer />
    </div>
  )
}

export default FundingAdsPage