import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : {
      getPackageList: () => {
        return Api.get(`v1/listing-package`);
      },
    };