import React from 'react'
import Navbar from '../Navbar'
import { Link } from 'react-router-dom'
import FooterBanner from '../FooterBanner'
import Footer from '../Footer'

function CookiePolicy() {
  return (
    <div>
    <Navbar />
    <FooterBanner title = {'COOKIE POLICY'} />
    <div className="w-full pt-10 flex justify-center">
      <div className="w-10/12">
        {/* <div className="w-full flex justify-center text-center">
          <div className="text-4xl font-bold pt-2 pb-4 text-blue-950">
          COOKIE POLICY
          </div>
        </div>
        <div className="bg-blue-400">
          <hr className="border border-blue-600"></hr>
        </div> */}
        <div className="">
              <div className="pt-2 pb-2">This policy describes how World Wide Adverts uses cookies on worldwideadverts.info (the “Site”). We recommend that you also consult our Privacy Policy for additional information on how we collect and use information collected from visitors to the Site. This policy is effective March 3, 2018. Your continued use of the Site after that will signify your acceptance of this policy. We may modify this Agreement without notifying you, so please check back often for updates.</div>             
              <div className="pt-2 pb-2">By using the Site, you agree that we can use the cookies described in this Cookie Policy. You can stop cookies by changing the settings in your browser (more information on how to do this is provided below).</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">WHAT ARE COOKIES?</div>
              <div className="pt-2 pb-2">Cookies are text files, containing small amounts of information, which are downloaded to your browsing device (such as a computer or smartphone) when you visit a website. Cookies can be recognized by the website that downloaded them — or other websites that use the same cookies. This helps websites know if the browsing device has visited them before.   </div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">WHAT ARE COOKIES USED FOR?</div>
              <div className="pt-2 pb-2">Cookies do lots of different jobs, like helping us understand how the Site is being used, letting you navigate between pages efficiently, remembering your preferences, and generally improving your browsing experience. Cookies can also help ensure marketing you see online is more relevant to you and your interests.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">WHAT TYPES OF COOKIES DOES WORLD WIDE ADVERTS USE?</div>
              <div className="pt-2 pb-2">The type of cookies used on most websites can generally be put into one of six categories: Strictly Necessary, Performance, Functionality, Flash, Tailored Content and Targeting. In order to provide you with the best browsing experience, Upwork uses all of these categories on the Site. You can find out more about each cookie category in the sections below.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">STRICTLY NECESSARY COOKIES</div>
              <div className="pt-2 pb-2">These cookies are essential, as they enable you to move around the Site and use its features, such as accessing secure areas. Without these cookies, some services you have asked for such as payment submission can’t be provided.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">PERFORMANCE COOKIES</div>
              <div className="pt-2 pb-2">These cookies collect information about how you use the Site, for example, which pages you go to most often and if you get error messages from certain pages. These cookies don’t gather information that identifies you. All information these cookies collect is anonymous and is only used to improve how the Site works.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">FUNCTIONALITY COOKIES</div>
              <div className="pt-2 pb-2">These cookies allow the Site to remember choices you make (such as your user name, language or the region you’re in). For instance, the Site uses functionality cookies to remember your language preference. These cookies can also be used to remember changes you’ve made to text size, font and other parts of pages that you can customize. They may also be used to provide services you’ve asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymous and they cannot track your browsing activity on other websites.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">FLASH COOKIES</div>
              <div className="pt-2 pb-2">We may, in certain circumstances, use Adobe Flash Player to deliver special content, such as video clips or animation. To improve your user experience, Local Shared Objects (commonly known as “Flash cookies”) are employed to provide functions such as remembering your settings and preferences. Flash cookies are stored on your device, but they are managed through an interface different from the one provided by your web browser. This means it is not possible to manage Flash cookies through your browser in the same way you would normally manage cookies. Instead, you can access your Flash management tools from Adobe’s website at <Link to="#" className='truncate text-cyan-600'>www.macromedia.com.</Link></div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">TAILORED CONTENT COOKIES</div>
              <div className="pt-2 pb-2">Tailored content cookies help the Site provide enhanced features and display content in a way that is relevant to you. These cookies help the Site determine what information to show you based on how you have used the Site previously. These cookies do not track your browsing activity on other websites.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">TARGETING COOKIES</div>
              <div className="pt-2 pb-2">These cookies are used to deliver advertisements that are more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of an advertising campaign. They remember that you have visited a website and this information may be shared with other organizations such as advertisers. This means after you have been to the Site you may see some advertisements about our services elsewhere on the Internet.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">HOW LONG WILL COOKIES STAY ON MY BROWSING DEVICE?</div>
              <div className="pt-2 pb-2">The length of time a cookie will stay on your browsing device depends on whether it is a “persistent” or “session” cookie. Session cookies will only stay on your device until you stop browsing. Persistent cookies stay on your browsing device until they expire or are deleted.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">FIRST AND THIRD PARTY COOKIES</div>
              <div className="pt-2 pb-2">First party cookies are cookies that belong to us, while third-party cookies are cookies that another party places on your browsing device through our Site. For example, Facebook will place a cookie on your browsing device if you click on the Facebook link when browsing the Site.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">HOW TO CONTROL AND DELETE COOKIES THROUGH YOUR BROWSER</div>
              <div className="pt-2 pb-2">The browser you are using to view the Site can enable, disable or delete cookies. To do this, follow the instructions provided by your browser (usually located within the “Help,” “Tools” or “Edit” facility). Please note that if you set your browser to disable cookies, you may not be able to access certain parts of the Site, for example, applying for a job or posting a job. Other parts of the Site may also not work properly. You can find out more information about how to change your browser cookie settings at <span className='text-cyan-600'> www.allaboutcookies.org</span>.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Summary</div>
              <div className="pt-2 pb-2">When you visit or interact with our sites, services, applications, tools or messaging, we or our authorised service providers may use cookies, web beacons, and other similar technologies for storing information to help provide you with a better, faster, and safer experience, and for advertising purposes.</div>             
              <div className="pt-2 pb-2">This page is designed to help you understand more about these technologies and our use of them on our sites and in our services, applications, and tools. Below is a summary of a few key things you should know about our use of such technologies. You can review the contents of our full User Cookie Notice below.</div>             
              <div className="pt-2 pb-2">Our cookies and similar technologies have different functions. They are either necessary for the functioning of our services, help us improve our performance, give you extra functionalities, or help us to serve you relevant and targeted ads. We use cookies and similar technologies that only remain on your device for as long as you keep your browser active (session) and cookies and similar technologies that remain on your device for a longer period (persistent). You're free to block, delete, or disable these technologies if your device allows this. You can manage your cookies and your cookie preferences in your browser or device settings.</div>             
              <div className="pt-2 pb-2">Where possible, security measures are set in place to prevent unauthorised access to our cookies and similar technologies. A unique identifier ensures that only we and/or our authorised service providers have access to cookie data.</div>             
              <div className="pt-2 pb-2">Service providers are companies that help us with various aspects of our business, such as site operations, services, applications, advertisements and tools. We use some authorised service providers to help us to serve you relevant ads on our services and other places on the internet. These service providers may also place cookies on your device via our services (third party cookies). They may also collect information that helps them identify your device, such as IP-address, or other unique or device identifiers.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">CONTACTING US</div>
              <div className="pt-2 pb-2">If you have any questions about this Cookie Policy, please contact us at <span className='text-cyan-600'>worldwideadverts@gmail.com</span></div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900"></div>
              <div className="pt-2 pb-2"></div>             
        </div>
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default CookiePolicy