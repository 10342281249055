import axios from "axios";

// export default () => {
//   return axios.create({
//     baseURL: 'http://localhost:8080',
//     headers: {
//       'Content-Type': 'application/json',
//       authorization: `Bearer ${localStorage.getItem('token')}`,
//     },
//   })
// }const headers = {
//   'cache-control': 'no-cache, private',
//   'content-type': 'application/json',
// };

const baseURL = "https://api.worldwideadverts.info/api";
const bearerToken = `Bearer ${localStorage.getItem('token')}`;


export const server = async () => {
  try {
    const axiosInatance = axios.create({
      baseURL,
      headers: {
        "cache-control": "no-cache, private",
        "Content-Type": "application/json",
         Authorization: bearerToken,
      },
    });
    axiosInatance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        console.log(error);
        Promise.reject(error);
      }
    );

    axiosInatance.interceptors.response.use(
      (response) => {
        if (
          response.request.responseURL ===
          `${response.config.baseURL}/${response.config.url}`
        )
          return response;
        else throw new Error("Response URL does not match request URL");
      },
      (error) => {
        if (error.response) {
          return Promise.reject(error.response.data);
        } else {
          return Promise.reject({ message: "Network error" });
        }
      }
    );

    return axiosInatance;
  } catch (e) {}
};

export default {
  get: async (url, params) => (await server()).get(url, params),
  post: async (url, params) => (await server()).post(url, params),
  put: async (url, params) => (await server()).put(url, params),
  delete: async (url, params) => (await server()).delete(url, params),
};
