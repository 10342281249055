import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEbayAds } from "../slice/CategorySlice";
import { useNavigate } from "react-router-dom";
import EbayAdsData from "../data/ebay.json";
import moment from "moment";

function EbayAds() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const EbayAdsData = useSelector((store) => store.categories.ebayAds);

  const handleNavigate = (url) => {
    navigate(url);
  };

  // useEffect(() => {
  //   dispatch(getEbayAds());
  // }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center mb-5">
      <div className="w-11/12 h-16 gap-1 flex justify-start text-2xl font-bold">
        <span className="" style={{ color: "#253776" }}>
          Ebay
        </span>
        <span></span>
        <span className="" style={{ color: "#00C6DB" }}>
          Ads
        </span>
      </div>

      <div className="w-11/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* {EbayAdsData.data?.items?.map((item, index) => ( */}
        {EbayAdsData.map((item, index) => (
          <div
            key={item.id}
            className="rounded-lg overflow-hidden  drop-shadow-lg"
            style={{
              backgroundColor: item.background,
              color: item.color,
            }}
            // style={{
            //   backgroundColor: item.style.split(";")[0].split(":")[1].trim(),
            //   color: item.style.split(";")[1].split(":")[1].trim(),
            //    border: item.style?.includes("border-color") ? `2px solid ${item.style.split("border-color:")[1].split(";")[0].trim()}` : "",
            // }}
          >
            <div>
              <img src={item.imageUrl}></img>
            </div>
            <div className="p-4">
              <div className="py-1 text-sm font-medium">
                <span className=" py-1 px-3 rounded-3xl border ">
                  {item.groupName}
                </span>
              </div>
              <div className="py-1 text-xl font-semibold">
                <h3>{item.name}</h3>
              </div>
              <div className="py-1 text-sm">
                <p>{item.description}</p>
              </div>
              <div className="p-4 text-center font-semibold border border-gray-700">
                <a href={item.url} target="_blank" alt={item.name}>
                  Open Link
                </a>
              </div>
              <div className="py-1 font-medium">
                <p>Expires {moment(item.endDate).format("YYYY/MM/DD")}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EbayAds;
