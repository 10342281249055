import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrency, getFilterCat } from "../slice/CategorySlice";
import { getAdsListFilter } from "../slice/ListSlice";
import { useParams } from "react-router-dom";

function Filter() {
  const dispatch = useDispatch();
  const { skip, limit } = useParams();
  const categoryAdsData = useSelector((store) => store.categories.catFilter);
  const CatFilter = categoryAdsData?.data || [];
  const catCurrency = useSelector((store) => store.categories.currency);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [negotiablePrice, setNegotiablePrice] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const handleCityChange = (city, selectedCities) => {
    const updatedCities = selectedCities.includes(city)
      ? selectedCities.filter((c) => c !== city)
      : [...selectedCities, city];
    setSelectedCities(updatedCities);
  };

  const handleCurrencyChange = (selectedCurrency) => {
    setSelectedCurrency(selectedCurrency);
  };

  const applyFilters = () => {
    dispatch(
      getAdsListFilter({
        category: selectedCategory,
        skip: 0,
        limit: 10,
        currencies: selectedCurrency,
        min_price: minPrice,
        max_price: maxPrice,
      })
    );
  };
  const clearFilters = () => {
    setSelectedCategory([]);
    setSelectedSubcategory([]);
    setSelectedCities(null);
    setSelectedCurrency(null);
    setNegotiablePrice(null);
    setCitySearch(null);
    setMinPrice(null);
    setMaxPrice(null);
  };
  const { slug } = useParams();
  useEffect(() => {
    setSelectedCategory(slug);
  }, [slug]);

  useEffect(() => {
    dispatch(getFilterCat());
    dispatch(getCurrency());
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryData = CatFilter?.items?.find(
        (item) => item?.slug == selectedCategory
      );
      if (selectedCategoryData) {
        setSubcategories(selectedCategoryData.childs || []);
      } else {
        setSubcategories([]);
      }
    }
  }, [selectedCategory, CatFilter]);
  return (
    <div className="w-full flex flex-col items-start gap-5 md:w-full lg:w-1/3 xl:w-1/3">
      <div className="w-full lg:w-11/12 bg-slate-200 p-4 rounded-xl">
        <div className="text-lg font-semibold">FILTER BY CATEGORY</div>
        <div className="pt-4 pb-2">
          <select
            className="w-full h-10 p-2 rounded-lg border border-gray-400"
            value={selectedCategory}
            onChange={(e) => {
              const category = e.target.value;
              setSelectedCategory(category);
            }}
          >
            <option value="">Select a Category</option>
            {CatFilter?.items?.map((category) => (
              <option key={category.category_id} value={category.slug}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      
        {subcategories.length > 0 && selectedCategory && (
          <div className="pt-4 pb-2">
            <select
              className="w-full h-10 p-2 rounded-lg border border-gray-400"
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
            >
              <option value="">Select a Subcategory</option>
              {subcategories.map((subcategory) => (
                <option key={subcategory.slug} value={subcategory.slug}>
                  {subcategory.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div className="w-full lg:w-11/12 bg-slate-200 p-4 rounded-xl">
        <div className="text-lg font-semibold">FILTER BY CITIES</div>
        <div className="pt-4 pb-2">
          <input
            type="text"
            className="w-full h-10 p-2 rounded-lg border border-gray-400"
            placeholder="Search for Cities"
            value={citySearch}
            onChange={(e) => setCitySearch(e.target.value)}
          />
        </div>
        {/* Display selected cities */}
        {selectedCities?.length > 0 && (
          <div className="pb-2">
            <label className="block font-semibold">Selected Cities:</label>
            {selectedCities.map((city) => (
              <div key={city}>{city}</div>
            ))}
          </div>
        )}
      </div>

      <div className="w-full lg:w-11/12 bg-slate-200 p-4 rounded-xl">
        <div className="text-lg font-semibold">FILTER BY CURRENCIES</div>
        <div className="pt-4 pb-2">
          <div>
            <label className="block font-semibold">Select Currencies:</label>
            <select
              className="w-full h-10 p-2 rounded-lg border border-gray-400"
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
            >
              <option value="">Select a Currencies</option>
              {catCurrency?.data?.items.map((currency) => (
                <option key={currency.currency_id} value={currency.name}>
                  {currency.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-11/12 bg-slate-200 p-4 rounded-xl">
        <div className="text-lg font-semibold">FILTER BY PRICE</div>
        <div className="flex justify-between gap-5 w-full pt-2 pb-2">
          <input
            type="text"
            className="w-full rounded-lg h-10 p-2 border border-gray-400"
            placeholder="Min Price"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
          />

          <input
            type="text"
            className="w-full rounded-lg h-10 p-2 border border-gray-400"
            placeholder="Max Price"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </div>
      </div>

      <div className="w-full lg:w-11/12 bg-slate-200 p-4 rounded-xl">
        <div className="text-lg font-semibold">NEGOTIABLE PRICE</div>
        <div className="pt-4 pb-2">
          <hr></hr>
          <div>
            <label>
              <input
                type="radio"
                value="yes"
                className="mr-2"
                checked={negotiablePrice === "yes"}
                onChange={() => setNegotiablePrice("yes")}
              />
              Yes
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                value="no"
                className="mr-2"
                checked={negotiablePrice === "no"}
                onChange={() => setNegotiablePrice("no")}
              />
              No
            </label>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-11/12 bg-slate-200 p-4 rounded-xl">
        <div className="py-2 flex justify-evenly">
          <button
            className="w-1/3 h-10 rounded-xl border border-blue-500 bg-blue-500 text-white"
            onClick={applyFilters}
          >
            Apply Filters
          </button>
          <button
            className="w-1/3 h-10 rounded-xl border border-red-500 bg-red-500 text-white"
            onClick={clearFilters}
          >
            Clear Filters
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filter;
