/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { useLocation } from 'react-router-dom'
import Navbar2 from '../Component/Navbar2'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const AffiliatePage = () => {
  let query = useQuery();
  return (
    <div className='overflow-hidden'>
        <Navbar2 />
        <div className='w-full h-full pt-20 overflow-hidden'>
          <iframe className='absolute' width='100%' height="100%" title='' src={query.get('url')}></iframe>
        </div>
    </div>
  )
}
export default AffiliatePage
