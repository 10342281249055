import React from "react";

import {GrPrevious, GrNext } from "react-icons/gr";

const PrevNextbtn = ({currentPage,totalPages,onPageChange,indexOfLastItem}) => {
  return (
    <div className="mx-auto w-full py-5 flex justify-between flex-col sm:flex-row">
      <p className="text-lg flex gap-1 items-end py-2">
        showing <span className="font-semibold">
            {
                // currentPage !== totalPages ? indexOfLastItem : data.length
            }
        </span> of
        {/* <span className="font-semibold">{data.length}</span> results */}
      </p>
      <div className="flex justify-between gap-4 py-2">
        <button className="flex justify-center items-center text-md font-semibold border-2 border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4" onClick={()=>onPageChange(currentPage -1)} disabled={currentPage === 1}>
          <GrPrevious className="text-xl hover:text-white" />
        </button>
        <button className="flex justify-center items-center text-md font-semibold border-2 border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4" onClick={()=> onPageChange(currentPage+1)} disabled={currentPage === totalPages}>
          <GrNext className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default PrevNextbtn;