import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "./MultiSelectDropdown";
import DataDummy from "../data/dummy-banner.json";
import BannerDetail from "./BannerDetail";
import { getBannerList, getMyBanner } from "../slice/BannerSlice";
import { Gallery } from "react-grid-gallery";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import BannerDetailForAuthor from "./BannerDetailForAuthor";

const useMediaQuery = () => {
  const [screenSize, setScreenSize] = useState("2xl");

  const checkScreenSize = () => {
    const width = window.innerWidth;
    if (width < 640) {
      setScreenSize("xs");
    } else if (width >= 640 && width < 768) {
      setScreenSize("sm");
    } else if (width >= 768 && width < 1024) {
      setScreenSize("md");
    } else if (width >= 1024 && width < 1280) {
      setScreenSize("lg");
    } else if (width >= 1280 && width < 1536) {
      setScreenSize("xl");
    } else {
      setScreenSize("2xl");
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return screenSize;
};

const MyBannerList = () => {
  const dispatch = useDispatch();
  const categoryAdsData = useSelector((store) => store.categories.categoryList);
  const bannerListData = useSelector((store) => store.banner.myBannerList);
  const { loading } = useSelector((store) => store.banner);

  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [data, setData] = useState([]);
  const [dataNew, setDataNew] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [page, setPage] = useState(1);
  const limit = 20;
  const sort_type = "desc";
  const [isOpen, setIsOpen] = useState(false);

  const loader = useRef(null);
  useEffect(() => {
    const options = categoryAdsData?.data?.items.map((o) => {
      {
        return {
          CategoryID: o.category_id,
          Name: o.name,
        };
      }
    });
    setOptionsCategory(options);
    return () => {};
  }, [categoryAdsData]);

  // useEffect(() => {
  //   // console.log(data);
  //   return () => {};
  // }, [data]);
  const init = () => {
    const skip = (page - 1) * limit;
    dispatch(getMyBanner({ skip, limit, sort_type }));
  };
  useEffect(() => {
    init();
  }, [page, dispatch]);

  const screenSize = useMediaQuery();
  useEffect(() => {
    setDataNew([]);
    if (bannerListData?.data?.items?.length) {
      const dataMap = bannerListData?.data?.items.map((item) => {
        // const size = item.size_img?.split("x");

        return {
          ...item,
          src: item.img,
          // width: size ? parseInt(size[0]) : 0,
          // height: parseInt(size ? size[1] ?? size[0] : 0),
        };
      });
      // console.log(dataMap);
      setDataNew([...dataNew, ...dataMap]);
    }
  }, [bannerListData]);
  const onRefresh = () => {
    setDataNew([]);
    setPage(1);
    init();
  };
  return (
    <div className="relative pt-32 pb-10 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col sm:flex-row sm:flex justify-between py-5"></div>
      <div className="p-10 bg-gray-200 rounded-2xl flex justify-between items-center mb-5">
        <div className="flex-1">
          <h1 className="text-blue-500 font-bold text-4xl mb-5">
            <span className="text-[#234777]">My Banner</span>
            <span className="text-[#01C6DA]"> Adverts</span>
          </h1>
          <p>
            Maximize your website's revenue potential by integrating banner
            affiliate ads, allowing you to earn commissions for every click or
            conversion generated through your eye-catching advertisements.
          </p>
          <div className="flex mt-8">
            <Link
              key={"banner"}
              to={`/postbanner`}
              className="flex items-center gap-2 text-lg g:px-7 px-4 py-2 bg-gradient-to-r from-blue-900 to-blue-500 text-white font-bold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg"
            >
              <BsFillPlusCircleFill /> <span className="">POST NEW BANNER</span>
            </Link>
          </div>
        </div>
        <div className="flex flex-1 justify-end">
          <img
            className="transform duration-500 hover:scale-105 w-[250px] object-cover"
            src="/img/ilustration-banner.png"
            alt="header"
          />
        </div>
      </div>
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow flex flex-col justify-between">
          <InfiniteScroll
            dataLength={dataNew.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={true}
            loader={
              <div ref={loader} style={{ height: "200px" }}>
                {loading && (
                  <div className="flex gap-5">
                    <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                      <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                        <svg
                          className="w-10 h-10 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                      <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                        <svg
                          className="w-10 h-10 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                      <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                        <svg
                          className="w-10 h-10 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4 }}
            >
              <Masonry gutter="20px">
                {dataNew.map((item, i) => (
                  <button
                    onClick={() => {
                      setSelectedData(item);
                      setIsOpen(true);
                    }}
                    key={i}
                    className="w-full block overflow-hidden"
                  >
                    <img
                      className="w-full rounded-md h-auto max-h-[500px] object-cover"
                      src={item.img}
                      alt={item.title}
                    />
                  </button>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </InfiniteScroll>
        </div>
      </div>
      <BannerDetailForAuthor
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={selectedData}
        onRefresh={() => {
          onRefresh();
        }}
      />
    </div>
  );
};
export default MyBannerList;
