import React from 'react'
import Navbar from '../Component/Navbar'
import NewAds from '../Component/NewAds'
import Footer from '../Component/Footer'
import BottomAds from '../Component/BottomAds'
import TopAds from "../Component/TopAds";

function NewAdsPage() {
  return (
    <div className=''>
        <Navbar />
        <TopAds />
        <NewAds />
        <BottomAds />
        <Footer />
    </div>
  )
}

export default NewAdsPage