import React from 'react'
import Navbar from '../Component/Navbar'
import FeatureAds from '../Component/FeatureAds'
import Footer from '../Component/Footer'
import BottomAds from '../Component/BottomAds'
import TopAds from "../Component/TopAds";

function FeatureAdsPage() {
  return (
    <div>
        <Navbar />
        <TopAds />
        <FeatureAds />
        <BottomAds />
        <Footer />
    </div>
  )
}

export default FeatureAdsPage