import React from 'react'
import Navbar from '../Navbar'
import FooterBanner from '../FooterBanner'
import Footer from '../Footer'

function Company() {
  return (
    <div>
      <Navbar />
      <FooterBanner title={'Company'} />
      <div className="w-full pt-10 flex justify-center">
        <div className="w-10/12">
          
          <div className="">
                
                <div className="pt-4 pb-4">World Wide Adverts is a dynamic and innovative online marketing company, officially registered under the number 13241715. Our primary objective is to connect individuals and businesses from around the world through trade and advertising. With our cutting-edge digital marketing platform, we offer a comprehensive marketplace where businesses of all sizes, from small ventures to large corporations, can showcase their products and services to a global audience.</div>
                <div className="pt-4 pb-4">At World Wide Adverts, we believe in the power of effective marketing techniques to help businesses reach and engage with a broader customer base. Our dedicated team of experts utilizes a diverse range of strategies and tools to ensure that our clients achieve optimal results in expanding their customer reach and driving growth. We pride ourselves on our commitment to providing world-class service to all our customers, including buyers, sellers, and service providers alike.</div>
                <div className="pt-4 pb-4">As part of our vision, we are actively working towards establishing the largest online business directory, encompassing enterprises from every corner of the globe. By consolidating vast business information into one centralized platform, we aim to facilitate effortless access to any business worldwide. Whether you are seeking local establishments or exploring opportunities in distant markets, World Wide Adverts will be your go-to resource.</div>
                <div className="pt-4 pb-4">To further enhance the visibility of products and services, we offer featured listings to our clients for a nominal fee. This additional exposure helps attract a larger customer base, ultimately contributing to business growth and success. We understand that each customer has unique goals and aspirations, and our team is dedicated to ensuring that those objectives are met with the utmost satisfaction.</div>
                <div className="pt-4 pb-4">Our overarching mission is to empower both individuals and businesses to achieve unprecedented success in the global marketplace. By transcending geographical borders and fostering connections, we strive to create an environment where businesses can thrive and individuals can access a world of opportunities. At World Wide Adverts, we believe that success should know no boundaries.</div>
                <div className="pt-4 pb-4">In summary, World Wide Adverts is a leading digital marketing company that facilitates global trade and advertising. Through our state-of-the-art platform and unwavering commitment to customer satisfaction, we aim to unlock the full potential of businesses worldwide. Join us on this transformative journey and experience the benefits of expanding your horizons with World Wide Adverts.</div>
                
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Company
