import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBooks } from "../slice/BookSlice";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { Link } from "react-router-dom";

import {
  FaCar,
  FaFileInvoice,
  FaHouseUser,
  FaIndustry,
  FaPencilAlt,
  FaShoppingBag,
  FaBuysellads,
  FaFighterJet,
  FaCreditCard,
  FaBalanceScale,
  FaCalendar,
  FaBuilding,
  FaBus,
  FaLaptop,
  FaTags,
  FaBook,
  FaMoneyBillWave,
} from "react-icons/fa";
import { PiFlagBanner } from "react-icons/pi";
const AllCategory = () => {
  const categoryAdsData = useSelector((store) => store.categories.categoryList);
  const categoryAds = categoryAdsData?.data || [];
  const getIcon = (iconname) => {
    switch (iconname) {
      case "fa-industry":
        return <FaIndustry size={40} />;
      case "fa-credit-card":
        return <FaCreditCard size={40} />;
      case "fa-fighter-jet":
        return <FaFighterJet size={40} />;
      case "fa-shopping-bag":
        return <FaShoppingBag size={40} />;
      case "fa-balance-scale":
        return <FaBalanceScale size={40} />;
      case "fa-calendar":
        return <FaCalendar size={40} />;
      case "fa-building":
        return <FaBuilding size={40} />;
      case "fa-bus":
        return <FaBus size={40} />;
      case "fa-laptop":
        return <FaLaptop size={40} />;
      case "fa-tags":
        return <FaTags size={40} />;
      case "fa-book":
        return <FaBook size={40} />;
      case "fa-money":
        return <FaMoneyBillWave size={40} />;
      case "banner":
        return <PiFlagBanner size={40} />;
      default:
        return <FaBuysellads size={40} />;
    }
  };
  return (
    <div className="relative pt-32 pb-10 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col sm:flex-row  sm:flex justify-between py-5">
        <h1 className="text-blue-500 font-bold text-4xl">
          <span className="text-[#234777]">All</span>
          <span className="text-[#01C6DA]"> Categories</span>
        </h1>
      </div>
      <hr className="border border-[#234777] mb-10" />
      <section className="p-5 md:p-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 items-start ">
        {categoryAds.items?.map((item, index) => (
          <Link key={item.category_id} to={`/category/${item.slug}`}>
            <div
              key={index}
              className="flex flex-col items-center justify-center border-2 rounded-2xl bg-slate-600 text-white bg-gradient-to-br hover:from-cyan-600 hover:to-blue-900 transition duration-500 ease-in-out px-5 h-40 gap-5"
            >
              {/* <AiFillHome className="mr-2" /> */}
              {getIcon(item.icon)}
              {item.name}
            </div>
          </Link>
        ))}
        <Link to={`/book/`}>
          <div className="flex flex-col items-center justify-center border-2 rounded-2xl bg-slate-600 text-white bg-gradient-to-br hover:from-cyan-600 hover:to-blue-900 transition duration-500 ease-in-out px-5 h-40 gap-5">
            <FaBook size={40} />
            Book
          </div>
        </Link>
        <Link to={`/banner/`}>
          <div className="flex flex-col items-center justify-center border-2 rounded-2xl bg-slate-600 text-white bg-gradient-to-br hover:from-cyan-600 hover:to-blue-900 transition duration-500 ease-in-out px-5 h-40 gap-5">
            <PiFlagBanner size={40} />
            Banner Adverts
          </div>
        </Link>
      </section>
    </div>
  );
};
export default AllCategory;
