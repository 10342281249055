import { useState, useEffect } from "react";
import { createStore, updateStore, getStore } from "../slice/StoreSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

function UpgradeToStore() {
  const dispatch = useDispatch();
  const storeDetail = useSelector((store) => store.store.storeDetail);

  const [formData, setFormData] = useState({
    store_id: "",
    store_name: "",
    company_name: "",
    company_no: "",
    vat: "",
    status: "",
  });

  const init = () => {
    dispatch(
      getStore({
        customer_id: "",
      })
    );
  };
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (storeDetail.data) {
      setFormData(storeDetail.data);
    }
  }, [storeDetail]);

  const onSubmit = async () => {
    try {
      if (formData.store_id) {
        await dispatch(
          updateStore({ store_id: formData.store_id, payload: formData })
        ).unwrap();
      } else {
        await dispatch(createStore(formData)).unwrap();
      }
      toast.success("Data has been saved");
      init();
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="w-full p-6">
      <h2 className="text-2xl text-center text-[#234777] font-bold mb-8">
        Upgrade To Store
      </h2>
      <p className="text-gray-600 mb-4">
        You can upgrade your account to store and have your own store page.
      </p>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600">
          Status
        </label>
        <select
          name="status"
          value={formData.status}
          onChange={(e) => {
            setFormData({ ...formData, status: e.target.value });
          }}
          className="mt-1 p-2 w-full border rounded-md"
        >
          <option value="active">Active</option>
          <option value="inactive">Disable</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block mb-2 text-[#234777] font-semibold">
          Company Name:
          <input
            type="text"
            name="companyName"
            required
            placeholder="Company Name"
            className="form-input mt-1 p-2 py-2 w-full border border-slate-300 outline-blue-500"
            value={formData.company_name}
            onChange={(e) => {
              setFormData({ ...formData, company_name: e.target.value });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          Company No:
          <input
            type="text"
            name="companyNo"
            required
            placeholder="Company No"
            className="form-input w-full mt-1 p-2 py-2 border border-slate-300 outline-blue-500"
            value={formData.company_no}
            onChange={(e) => {
              setFormData({ ...formData, company_no: e.target.value });
            }}
          />
        </label>
        <label className="block mb-2 text-[#234777] font-semibold">
          VAT:
          <input
            type="text"
            name="vat"
            required
            placeholder="VAT"
            className="form-input w-full mt-1 p-2 py-2 border border-slate-300 outline-blue-500"
            value={formData.vat}
            onChange={(e) => {
              setFormData({ ...formData, vat: e.target.value });
            }}
          />
        </label>
      </div>

      <div className="w-full flex justify-center">
        <button onClick={onSubmit} className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600">
          SAVE CHANGES
        </button>
      </div>
    </div>
  );
}

export default UpgradeToStore;
