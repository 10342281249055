import React from 'react'
import Navbar from '../Navbar'
import FooterBanner from '../FooterBanner'
import Footer from '../Footer'

function TermsOfUse() {
  return (
    <div>
        <Navbar />
        
        <FooterBanner title={'TERMS OF USE'} />
        <div className="w-full pt-10 flex justify-center">
            <div className="w-10/12">
                {/* <div className="w-full flex justify-center">
                    <div className="text-4xl font-bold pt-2 pb-4 text-blue-950">TERMS OF USE</div>
                </div>
                <div className="bg-blue-400">
                    <hr className="border border-cyan-600"></hr>
                </div> */}
                <div className="">
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">TERMS OF USE FOR WORLDWIDE ADVERTS</div>
                    <div className="pt-2 pb-2">(Effective from July 6, 2018)</div>
                    <div className="pt-2 pb-2">By accessing this website, you are agreeing to be bound by the website Terms of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Proprietary Rights in Site</div>
                    <div className="pt-2 pb-2">All content on this website, including designs, computer code, text, graphics, pictures, and other works and their selection and arrangement (collectively, "Site Content") are the proprietary property of Worldwide Adverts and our licensors. No Site Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, without Worldwide Adverts’ prior written permission. You are granted a limited license to access and use this website and the Site Content in accordance with these Terms of Use. You may not upload or republish Site Content on any Internet, intranet or extranet site or incorporate the information in any other database or compilation, and any other use of the Site Content is strictly prohibited. Such license does not permit the use of any data mining, robots, scraping or similar data gathering or extraction methods. Unless explicitly stated herein, nothing in these Terms of Use shall be construed as conferring any license to intellectual property rights.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Acceptable Use</div>
                    <div className="pt-2 pb-2">You must not misuse this Website. You will not: commit or encourage a criminal offence; transmit or distribute a virus, Trojan, worm, logic bomb or any other material which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene; hack into any aspect of the website; corrupt data; cause annoyance to other users; infringe upon the rights of any other person's proprietary rights; or attempt to affect the performance or functionality of any computer facilities of or accessed through this Website. Breaching this provision would constitute a criminal offence and WorldwideAdverts.info will report any such breach to the relevant law enforcement authorities and disclose your identity to them.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Disclaimer</div>
                    <div className="pt-2 pb-2">All contents on this website are provided "as is". Worldwide Adverts makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, World Wide Adverts does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the Site Content otherwise relating to such content or on any sites linked to this site.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Limitations</div>
                    <div className="pt-2 pb-2">In no event shall World Wide Adverts or its owners be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the Site Content, even if World Wide Adverts or a World Wide Adverts authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Revisions and Errors</div>
                    <div className="pt-2 pb-2">The content appearing on World Wide Adverts’ website could include technical, typographical, or photographic errors. World Wide Adverts does not warrant that any of the Site Contents are accurate, complete, or current. World Wide Adverts may make changes to the content contained on its website at any time without notice. World Wide Adverts does not, however, make any commitment to update the Site Content.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Links</div>
                    <div className="pt-2 pb-2">World Wide Adverts has not reviewed all of the sites linked to its Internet website and is not responsible for the content of any such linked site. The inclusion of any link does not imply endorsement by World Wide Adverts of the site. Use of any such linked website is at the user's own risk.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Site Terms of Use Modifications</div>
                    <div className="pt-2 pb-2">World Wide Adverts may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms of Use.</div>
                    <div className="text-lg font-bold text-blue-900 pt-4 pb-4">Handling Complaints and Suggestions</div>
                    <div className="pt-2 pb-2">Questions, comments, and requests regarding this Terms of Use are welcomed and should be addressed to worldwideadvertsinfo@gmail.com.</div>
    
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default TermsOfUse