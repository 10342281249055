import React from "react";
import { Link } from "react-router-dom";

const BusinessPage = () => {
  return (
    <div>
      <div className="w-full fixed z-20 bg-white shadow shadow-slate-300">
        <div className="flex justify-between h-20 w-11/12 m-auto items-center">
          <div className="flex gap-4 items-center">
            <Link to="/">
              <img src="/img/wwaLogo.png" alt="logo" className="w-48 sm:w-56" />
            </Link>
          </div>
          <div>
            <Link to="/" className="text-blue-600 hover:text-black">
              Back to Website
            </Link>
          </div>
        </div>
      </div>

      <div className="pt-24">
        <iframe
          title="Website Content"
          src="https://www.example.com" 
          width="100%"
          height="600px" 
        ></iframe>
    {/* <div className="max-w-screen-md mx-auto bg-white p-8 shadow-md rounded-md">
      <div className=" text-center text-2xl font-bold mb-8">Purchase Order</div>
      <hr className="my-4" />
      <div className=" flex justify-end items-center mb-8">
        <div className=" text-left">
          <p><strong>Date:</strong> January 1,2023</p>
          <p><strong>Purchase Order #:</strong>PO12345</p>
        </div>
      </div>
      <div className="flex justify-between mb-8 p-2">
        <div className=" w-[45%] border border-blue-400 p-2">
          <h3>Order Details</h3>
          <p><strong> Name:</strong> ABC Vendor</p>
          <p><strong> Address:</strong> 123 Vendor Street, City</p>
        </div>
        <div className=" w-[45%] border border-blue-400 p-2">
          <h3>Manufacturer Details</h3>
          <p><strong> Name:</strong> XYZ Manufacturer</p>
          <p><strong> Address:</strong> 456 Manufacturer Street, City</p>
        </div>
      </div>
      <div>
        <h2>Purchase Products List</h2>
        <table className="w-full border-collapse border border-gray-300 mt-4">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2">Product Name</th>
              <th className="p-2">Description</th>
              <th className="p-2">Quantity</th>
              <th className="p-2">Unit Price</th>
              <th className="p-2">Total</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr className="border-b">
              <td className="p-2">Product A</td>
              <td className="p-2">Descriptions...</td>
              <td className="p-2">10</td>
              <td className="p-2">$50.00</td>
              <td className="p-2">$500.00</td>
            </tr>
            <tr className="border-b">
              <td className="p-2">Product B</td>
              <td className="p-2">Descriptions...</td>
              <td className="p-2">5</td>
              <td className="p-2">$30.00</td>
              <td className="p-2">$150.00</td>
            </tr>
            <tr className="border-b">
              <td className="p-2">Product C</td>
              <td className="p-2">Descriptions...</td>
              <td className="p-2">8</td>
              <td className="p-2">$20.00</td>
              <td className="p-2">$160.00</td>
            </tr>
          </tbody>
        </table> 
      </div>
      <div className=" mt-8 text-right">
        <p><strong>Total :</strong> $810.00</p>
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default BusinessPage;
