import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : {
      getCategoriesList: (is_parent) => {
        return Api.get(`v1/category?is_parent=${is_parent}`);
      },
      createCategoryList: (data) => {
        return Api.post("v1/category", data);
      },
      detailsCategory: (slug) => {
        return Api.get(`v1/category/${slug}`);
      },
      CategoryTreeChild: (id) => {
        return Api.get(`v1/category/tree?id=${id}`);
      },
      getEbayAds: () => {
        return Api.post("v1/listing/ebay");
      },
      getFilterCat: () => {
        return Api.get(`v1/category/tree`);
      },
      getCurrency: () => {
        return Api.get(`v1/master/currency`);
      },
      getCountry: () => {
        return Api.get(`v1/master/country`);
      },
      getZone: (payload) => {
        return Api.get(
          `v1/master/zone?country_id=${payload?.country_id ?? ""}`
        );
      },
    };
