import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StoreServices from "../services/StoreServices";

const initialState = {
  loading: false,
  storeAds: [],
  storeDetail: {},
  businessStore: {},
  message: null,
  error: null,
};

export const getStore = createAsyncThunk(
  "store/getStore",
  async ({ customer_id }) => {
    if (customer_id) {
      const res = await StoreServices.getStore(customer_id);
      return res.data;
    } else {
      const res = await StoreServices.getMyStore();
      return res.data;
    }
  }
);

export const getStoreAds = createAsyncThunk(
  "store/getStoreAds",
  async ({ customer_id, skip, limit }) => {
    const res = await StoreServices.getStoreAds(customer_id, skip, limit);
    return res.data;
  }
);

export const updateStore = createAsyncThunk(
  "store/updateStore",
  async ({ store_id, payload }) => {
    const res = await StoreServices.updateStore(store_id, payload);
    return res.data;
  }
);
export const createStore = createAsyncThunk(
  "store/createStore",
  async (payload) => {
    const res = await StoreServices.createStore(payload);
    return res.data;
  }
);
export const getBusinessStore = createAsyncThunk(
  "store/getBusinessStore",
  async ({ customer_id }) => {
    const res = await StoreServices.getBusinessStore(customer_id);
    return res.data;
  }
);
export const getBusinessStoreBySlug = createAsyncThunk(
  "store/getBusinessStoreBySlug",
  async ({ slug }) => {
    const res = await StoreServices.getBusinessStoreBySlug(slug);
    return res.data;
  }
);
export const updateBusinessStore = createAsyncThunk(
  "store/updateBusinessStore",
  async ({ business_id, payload }) => {
    const res = await StoreServices.updateBusinessStore(business_id, payload);
    return res.data;
  }
);
export const createBusinessStore = createAsyncThunk(
  "store/createBusinessStore",
  async (payload) => {
    const res = await StoreServices.createBusinessStore(payload);
    return res.data;
  }
);
const handleError = (state, action) => {
  console.error(action.error.message, action.error);
  state.error = action.error.message;
  state.businessStore = {};
};

const PackageSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    redirectFalse: (state) => {
      state.loading = false;
      state.redirect = false;
    },
    clearAdsErrorAndMessage: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: {
    [getStore.pending]: (state) => {
      state.loading = true;
    },
    [getStore.fulfilled]: (state, action) => {
      state.storeDetail = action.payload;
      state.loading = false;
    },
    [getStore.rejected]: handleError,

    [getStoreAds.pending]: (state) => {
      state.loading = true;
    },
    [getStoreAds.fulfilled]: (state, action) => {
      state.storeAds = action.payload;
      state.loading = false;
    },
    [getStoreAds.rejected]: handleError,

    [updateStore.fulfilled]: (state, action) => {
      state.message = action.payload.message;
      state.loading = false;
    },
    [updateStore.rejected]: handleError,

    [createStore.pending]: (state) => {
      state.loading = true;
    },
    [createStore.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createStore.rejected]: handleError,

    [getBusinessStore.pending]: (state) => {
      state.loading = true;
    },
    [getBusinessStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.businessStore = action.payload;
    },
    [getBusinessStore.rejected]: handleError,

    [getBusinessStoreBySlug.pending]: (state) => {
      state.loading = true;
    },
    [getBusinessStoreBySlug.fulfilled]: (state, action) => {
      state.loading = false;
      state.businessStore = action.payload;
    },
    [getBusinessStoreBySlug.rejected]: handleError,

    [updateBusinessStore.pending]: (state) => {
      state.loading = true;
    },
    [updateBusinessStore.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateBusinessStore.rejected]: handleError,

    [createBusinessStore.pending]: (state) => {
      state.loading = true;
    },
    [createBusinessStore.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createBusinessStore.rejected]: handleError,
  },
});

export const { clearAdsErrorAndMessage, redirectFalse } = PackageSlice.actions;
const { reducer } = PackageSlice;
export default reducer;
