import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : {
      getMyStore: () => {
        return Api.get(`v1/store/my-store`);
      },
      getStore: (customer_id) => {
        return Api.get(`v1/store/${customer_id}/detail`);
      },
      getStoreAds: (customer_id, skip, limit) => {
        return Api.get(
          `v1/store/${customer_id}/my-ads?skip=${skip}&limit=${limit}`
        );
      },
      updateStore: (id, payload) => {
        return Api.put(`v1/store/${id}`, payload);
      },
      createStore: (payload) => {
        return Api.post("v1/store", payload);
      },
      createBusinessStore: (payload) => {
        return Api.post("v1/business", payload);
      },
      updateBusinessStore: (id, payload) => {
        return Api.put(`v1/business/${id}`, payload);
      },
      getBusinessStoreBySlug: (slug) => {
        return Api.get(`v1/business/${slug}`);
      },
      getBusinessStore: (customer_id) => {
        return Api.get(`v1/business/${customer_id}/detail`);
      },
    };
