import React from "react";
import Navbar from "../Component/Navbar";
import MyAffiliateAds from "../Component/MyAffiliateAds";
import Footer from "../Component/Footer";
import BottomAds from "../Component/BottomAds";

function MyAffiliateAdsPage() {
  return (
    <div className="">
      <Navbar />
      <MyAffiliateAds />
      <BottomAds />
      <Footer />
    </div>
  );
}

export default MyAffiliateAdsPage;
