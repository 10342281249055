import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ListServices from "../services/ListServices";

const initialState = {
  loading: false,
  adsList: [],
  adsListSlide: [],
  catAdsList: [],
  detailsAds: [],
  updateAds: [],
  newAds: [],
  newAdsSlide: [],
  promotedAds: [],
  promotedAdsSlide: [],
  favouriteAds: [],
  favouriteAdsSlide: [],
  favouriteAdsDetail: [],
  globalSearch: [],
  myAds: [],
  blog: [],
  blogDetails: [],
  classified: [],
  message: null,
  error: null,
};

export const getAdsList = createAsyncThunk(
  "ads/getAdsList",
  async ({ category, skip, limit }) => {
    const res = await ListServices.getAdsList(category, skip, limit);
    return res.data;
  }
);

export const getAdsListFilter = createAsyncThunk(
  "ads/getAdsListFilter",
  async ({ category, skip, limit, currencies, max_price, min_price }) => {
    const res = await ListServices.getAdsListFilterApi(
      category,
      skip,
      limit,
      currencies,
      max_price,
      min_price
    );
    return res.data;
  }
);

export const createAdsList = createAsyncThunk(
  "ads/createAdsList",
  async ({ formData }) => {
    const res = await ListServices.createAdsList(formData);
    return res;
  }
);
export const updateAds = createAsyncThunk(
  "ads/updateAds",
  async ({ adsId, formData }) => {
    const res = await ListServices.updateAds(adsId, formData);
    return res;
  }
);
export const deleteAds = createAsyncThunk("ads/deleteAds", async (adsId) => {
  const res = await ListServices.deleteAds(adsId);
  return res;
});

export const detailsAdsList = createAsyncThunk(
  "ads/detailList",
  async ({ slug }) => {
    const res = await ListServices.detailsAdsList(slug);
    return res.data;
  }
);
export const updateFavAdsList = createAsyncThunk(
  "ads/updateFavAdsList",
  async ({ id }) => {
    const res = await ListServices.updateFavAdsList(id);
    return res.data;
  }
);
export const removeFabAds = createAsyncThunk(
  "ads/removeFavAds",
  async ({ id }) => {
    const res = await ListServices.removeFabAds(id);
    return res.data;
  }
);

export const getFeaturedAds = createAsyncThunk(
  "ads/getFeaturedAds",
  async ({ skip, limit }) => {
    const res = await ListServices.getFeaturedAds(skip, limit);
    return res.data;
  }
);
export const getFeaturedAdsSlide = createAsyncThunk(
  "ads/getFeaturedAdsSlide",
  async ({ skip, limit }) => {
    const res = await ListServices.getFeaturedAdsSlide(skip, limit);
    return res.data;
  }
);

export const getNewAds = createAsyncThunk(
  "ads/getNewAds",
  async ({ skip, limit }) => {
    const res = await ListServices.getNewAds(skip, limit);
    return res.data;
  }
);

export const getNewAdsSlide = createAsyncThunk(
  "ads/getNewAdsSlide",
  async ({ skip, limit }) => {
    const res = await ListServices.getNewAdsSlide(skip, limit);
    return res.data;
  }
);

export const getPromotedAds = createAsyncThunk(
  "ads/getPromotedAds",
  async ({ skip, limit }) => {
    const res = await ListServices.getPromotedAds(skip, limit);
    return res.data;
  }
);
export const getPromotedAdsSlide = createAsyncThunk(
  "ads/getPromotedAdsSlide",
  async ({ skip, limit }) => {
    const res = await ListServices.getPromotedAdsSlide(skip, limit);
    return res.data;
  }
);

export const getFavouriteAds = createAsyncThunk(
  "ads/favorite",
  async ({ skip, limit }) => {
    const res = await ListServices.getFavouriteAds(skip, limit);
    return res.data;
  }
);
export const getFavouriteAdsDetail = createAsyncThunk(
  "ads/favoriteDetail",
  async ({ id }) => {
    const res = await ListServices.getFavouriteAdsDetail(id);
    return res.data;
  }
);
export const getMyAds = createAsyncThunk(
  "ads/myads",
  async ({ id, skip, limit, status }) => {
    const res = await ListServices.getMyAds(id, skip, limit, status);
    return res.data;
  }
);
export const creatFavouriteAds = createAsyncThunk(
  "ads/createAdsList",
  async ({ data }) => {
    const res = await ListServices.creatFavouriteAds(data);
    return res.data;
  }
);
export const getClassified = createAsyncThunk("ads/getClassified", async () => {
  const res = await ListServices.getClassified();
  return res.data;
});

export const getGlobalSearch = createAsyncThunk(
  "ads/getGlobalSearch",
  async ({ searchData }) => {
    const res = await ListServices.getGlobalSearch(searchData);
    return res.data;
  }
);
export const getBlog = createAsyncThunk(
  "ads/getBlog",
  async ({ skip, limit }) => {
    const res = await ListServices.getBlog(skip, limit);
    return res.data;
  }
);
export const getBlogDetails = createAsyncThunk(
  "ads/getBlogDetails",
  async ({ id }) => {
    const res = await ListServices.getBlogDetails(id);
    return res.data;
  }
);

const handleError = (state, action) => {
  state.error = action.error.message;
  state.loading = false;
};

const ListSlice = createSlice({
  name: "ads",
  initialState,
  reducers: {
    redirectFalse: (state) => {
      state.redirect = false;
    },
    clearAdsErrorAndMessage: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: {
    [getAdsList.pending]: (state) => {
      state.loading = true;
    },
    [getAdsList.fulfilled]: (state, action) => {
      state.catAdsList = action.payload;
    },
    [getAdsList.rejected]: handleError,
    [createAdsList.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [createAdsList.rejected]: handleError,
    [updateAds.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [updateAds.rejected]: handleError,
    [deleteAds.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [deleteAds.rejected]: handleError,
    [getFeaturedAds.fulfilled]: (state, action) => {
      state.adsList = action.payload;
    },
    [getFeaturedAds.rejected]: handleError,
    [getFeaturedAdsSlide.fulfilled]: (state, action) => {
      state.adsListSlide = action.payload;
    },
    [getFeaturedAdsSlide.rejected]: handleError,
    [getNewAds.fulfilled]: (state, action) => {
      state.newAds = action.payload;
    },
    [getNewAds.rejected]: handleError,
    [getNewAdsSlide.fulfilled]: (state, action) => {
      state.newAdsSlide = action.payload;
    },
    [getNewAdsSlide.rejected]: handleError,
    [getPromotedAds.fulfilled]: (state, action) => {
      state.promotedAds = action.payload;
    },
    [getPromotedAds.rejected]: handleError,
    [getPromotedAdsSlide.fulfilled]: (state, action) => {
      state.promotedAdsSlide = action.payload;
    },
    [getPromotedAdsSlide.rejected]: handleError,

    [getFavouriteAds.fulfilled]: (state, action) => {
      state.favouriteAds = action.payload;
    },
    [getFavouriteAds.rejected]: handleError,
    [getFavouriteAdsDetail.fulfilled]: (state, action) => {
      state.favouriteAdsDetail = action.payload;
    },
    [getFavouriteAdsDetail.rejected]: handleError,
    [getMyAds.fulfilled]: (state, action) => {
      state.myAds = action.payload;
    },
    [getMyAds.rejected]: handleError,
    [creatFavouriteAds.fulfilled]: (state, action) => {
      state.favouriteAds = action.payload;
    },
    [creatFavouriteAds.rejected]: handleError,
    [updateFavAdsList.fulfilled]: (state, action) => {
      state.favouriteAds = action.payload;
    },
    [updateFavAdsList.rejected]: handleError,
    [detailsAdsList.fulfilled]: (state, action) => {
      state.detailsAds = action.payload;
    },
    [detailsAdsList.rejected]: handleError,
    [removeFabAds.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [removeFabAds.rejected]: handleError,
    [getAdsListFilter.pending]: (state, action) => {
      state.catAdsList = action.payload;
    },
    [getAdsListFilter.fulfilled]: (state, action) => {
      state.catAdsList = action.payload;
    },
    [getAdsListFilter.rejected]: (state, action) => {},
    [getAdsListFilter.rejected]: handleError,
    [getClassified.fulfilled]: (state, action) => {
      state.classified = action.payload;
    },
    [getClassified.rejected]: handleError,
    [getGlobalSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getGlobalSearch.fulfilled]: (state, action) => {
      state.globalSearch = action.payload;
      state.loading = false;
    },
    [getGlobalSearch.rejected]: handleError,
    [getBlog.fulfilled]: (state, action) => {
      state.blog = action.payload;
    },
    [getBlog.rejected]: handleError,
    [getBlogDetails.fulfilled]: (state, action) => {
      state.blogDetails = action.payload;
    },
    [getBlogDetails.rejected]: handleError,
  },
});

export const { clearAdsErrorAndMessage, redirectFalse } = ListSlice.actions;
const { reducer } = ListSlice;
export default reducer;
