import React, { useEffect, useState } from "react";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import { FaTags } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { BiExitFullscreen } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedAds } from "../slice/ListSlice";
import FeaturedAdsSlide from "../Component/FeaturedAdsSlide";
import ListingFormPopup from "../Component/ListingFormPopup";
import { PiPencil } from "react-icons/pi";
import ModalCategoryPostAd from "./ModalCategoryPostAd";

const FeatureAds = () => {
  const dispatch = useDispatch();
  const featuredAdsData = useSelector((store) => store.ads.adsList);
  const featuredAds = featuredAdsData?.data || [];
  const itemsPerPage = 20;
  const totalDataCount = featuredAds?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [liked, setLiked] = useState(Array(featuredAds.length).fill(false));
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const toggleLike = (index) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  const [singleColumnView, setSingleColumnView] = useState(true);
  const [multipleColumnView, setMultipleColumnView] = useState(false);

  // function for grid view
  const handleSingleColumnView = () => {
    setSingleColumnView(true);
    setMultipleColumnView(false);
  };

  const handleMultipleColumnView = () => {
    setSingleColumnView(false);
    setMultipleColumnView(true);
  };

  const totalPages = Math.ceil(featuredAds.length / itemsPerPage);

  const init = () => {
    dispatch(
      getFeaturedAds({
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  };
  useEffect(() => {
    init();
  }, [currentPage]);

  const truncateDescription = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  const onRefresh = () => {
    setCurrentPage(1);
    init();
  };
  return (
    <div className=" relative pt-32 pb-10 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col justify-between py-5">
        <div className="p-10 bg-yellow-100 rounded-2xl flex justify-between items-center mb-5">
          <div className="flex-1">
            <h1 className="text-blue-500 font-bold text-4xl mb-5">
              <span className="text-[#234777]">My Feature</span>
              <span className="text-[#01C6DA]"> Adverts</span>
            </h1>
            <p>
              Featured adverts are premium promotional spaces designed to
              capture attention, highlight key offers, and maximize visibility
              for businesses or products, ensuring they stand out in a
              competitive marketplace.
            </p>
            <div className="flex mt-8">
              <ModalCategoryPostAd />
            </div>
          </div>
          <div className="md:flex-1 justify-end hidden md:flex">
            <img
              className="transform duration-500 hover:scale-105 w-[400px] object-cover"
              src="/img/my-ads.png"
              alt="header"
            />
          </div>
        </div>
        {/* <h1 className="text-blue-500 font-bold text-4xl mb-5">
          <span className="text-[#234777]">Feature</span>
          <span className="text-[#01C6DA]"> Ads</span>
        </h1> */}
        <div className="pt-8 md:pt-0 flex justify-between gap-5 py-2">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <GrFormPreviousLink className="text-xl hover:text-white" />
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
            <GrFormNextLink className="text-xl hover:text-white" />
          </button>
        </div>
        {/* {JSON.stringify(JSON.parse(featuredAds).data.data)} */}
      </div>
      {featuredAds.items?.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 sm:justify-center md:grid-cols-2 lg:grid-cols-2 lg:justify-between">
          {featuredAds.items?.map((item, index) => (
            <div
              className="group w-full flex overflow-hidden border border-slate-200 shadow-lg rounded-lg"
              key={index}
            >
              <div className="w-1/3 h-56 bg-slate-100 flex items-center">
                <Link to={`/ads-detail/${item.slug}`}>
                  {item.images.length > 0 ? (
                    <img
                      src={item.images[0].image_path}
                      alt={item.title}
                      onError={(e) => {
                        e.target.src = "/img/no-image-featured.jpg";
                      }}
                      style={{ width: "100%", height: "100%" }}
                      className="object-cover transform duration-500 hover:scale-105"
                    />
                  ) : (
                    <img
                      src="/img/no-image-featured.jpg"
                      alt="Default"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </Link>
              </div>

              <div className="w-7/12 p-4">
                <div className="flex items-center gap-1 text-[#999999] pb-2">
                  <div>
                    <FaTags />
                  </div>
                  <div>{item.category.name}</div>
                </div>
                <div>
                  <hr className="border-1 text-[#999999]" />
                </div>
                <div className="text-lg text-[#232D3B] py-1">
                  <Link
                    to={`/ads-detail/${item.slug}`}
                    className="font-bold group-hover:text-yellow-500"
                  >
                    {item.title}
                  </Link>
                </div>
                <div className="flex items-start gap-1 pb-2 text-[#999999]">
                  <div className="pt-1">
                    <MdLocationOn />
                  </div>
                  <div>{item.location.city}</div>
                </div>
                <div>
                  <hr className="border-1 text-[#999999]" />
                </div>
                <div className="flex justify-end align-middle font-bold text-[#232D3B] text-lg py-2">
                  {/* <div className="font-bold text-[#232D3B] text-lg py-2">
                    <div>
                      {item.currency.symbol} {item.price}
                    </div>
                  </div> */}
                  <div className=" w-1/4 flex justify-end">
                    <div className=" border-slate-200">
                      <Link to={`/ads-detail/${item.slug}`} key={index}>
                        <button className="p-1 hover:scale-110 duration-300">
                          <BiExitFullscreen className="w-5 h-5" />
                        </button>
                      </Link>
                    </div>
                    <span className="border max-h-[70%]"></span>
                    <div className="  border-slate-300">
                      <button
                        className="p-1 hover:scale-110 duration-300"
                        onClick={() => {
                          setSelectedData(item);
                          setIsOpen(true);
                        }}
                      >
                        <PiPencil className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
          <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
            {" "}
            <span className="text-2xl italic">No results found</span>
          </div>
        </div>
      )}
      <div className="flex justify-between py-6">
        <div className="mt-2 text-lg">
          Showing{" "}
          <strong>
            {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
          </strong>{" "}
          of <strong>{totalDataCount}</strong> results.
        </div>
        <div className="flex justify-between gap-4 pt-6 lg:py-0">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <GrFormPreviousLink className="text-xl hover:text-white" />
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
            <GrFormNextLink className="text-xl hover:text-white" />
          </button>
        </div>
      </div>
      <FeaturedAdsSlide />
      <ListingFormPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={selectedData}
        onRefresh={() => {
          onRefresh();
        }}
      />
    </div>
  );
};

export default FeatureAds;
