import React, { useState } from "react";
import UserFavoriteAds from "./UserFavoriteAds";
import AccountInfo from "./AccountInfo";
import Invoices from "./Invoices";
import MyAds from "./MyAds";

const UserAccount = () => {

  const [activeComponent, setActiveComponent] = useState("AccountInfo");

  const renderComponent = () => {
    switch (activeComponent) {
      case "MyAds":
        return <MyAds />;
      case "FavoriteAds":
        return <UserFavoriteAds />;
      case "Invoices":
        return <Invoices />;
      case "AccountInfo":
        return <AccountInfo />;
      default:
        return  <AccountInfo />;
    }
  };
  const headerStyle = {
    backgroundImage: "url(https://worldwideadverts.info/assets/site/img/search-bg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="relative pt-32 lg:pt-20  bg-transparent">
      <header style={headerStyle} className=" bg-[#234777] p-4 text-xl font-semibold bg-no-repeat bg-cover min-h-20">
        <h1 className="text-white text-4xl font-bold text-center">MY ACCOUNT</h1>
        <div className="flex justify-center gap-4 mt-4">
          <button
            className={`${
              activeComponent === "MyAds" ? "bg-white text-[#234777]" : "bg-transparent border-2 border-white text-white"
            } p-1 px-5 text-lg font-semibold rounded-full`}
            onClick={() => setActiveComponent("MyAds")}
          >
            My Ads
          </button>
          <button
            className={`${
              activeComponent === "FavoriteAds" ? "bg-white text-[#234777]" : "bg-transparent border-2 border-white text-white"
            } p-1 px-5 text-lg font-semibold rounded-full`}
            onClick={() => setActiveComponent("FavoriteAds")}
          >
            Favorite Ads
          </button>
          <button
            className={`${
              activeComponent === "Invoices" ? "bg-white text-[#234777]" : "bg-transparent border-2 border-white text-white"
            } p-1 px-5 text-lg font-semibold rounded-full`}
            onClick={() => setActiveComponent("Invoices")}
          >
            Invoices
          </button>
          <button
            className={`${
              activeComponent === "AccountInfo" ? "bg-white text-[#234777]" : "bg-transparent border-2 border-white text-white"
            } p-1 px-5 text-lg font-semibold rounded-full`}
            onClick={() => setActiveComponent("AccountInfo")}
          >
            Account Info
          </button>
        </div>
      </header>
      {renderComponent()}
    </div>
  );
};

export default UserAccount;
