import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/AuthSlice";
import ListSlice from "./slice/ListSlice";
import CategorySlice from "./slice/CategorySlice";
import PackageSlice from "./slice/PackageSlice";
import FundingSlice from "./slice/FundingSlice";
import AffiliateSLice from "./slice/AffiliateSLice";
import BookSlice from "./slice/BookSlice";
import LanguageSlice from "./slice/LanguageSlice";
import StoreSlice from "./slice/StoreSlice";
import GeoLocSlice from "./slice/GeoLocationSlice";
import BannerSlice from "./slice/BannerSlice";

const reducer = {
  auth: authSlice,
  ads: ListSlice,
  categories: CategorySlice,
  package: PackageSlice,
  fund: FundingSlice,
  aff: AffiliateSLice,
  book: BookSlice,
  lang: LanguageSlice,
  store: StoreSlice,
  location: GeoLocSlice,
  banner: BannerSlice,
};

const store = configureStore({
  reducer: reducer,
});

export default store;
