import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : {
      getAffiliateList: (position, skip, limit) => {
        return Api.get(`v1/affiliate?skip=${skip}&limit=${limit}`);
      },
      getAffiliateListTop: () => {
        return Api.get(`v1/affiliate?position=top`);
      },
      createAffiliate: (payload) => {
        return Api.post(`v1/affiliate`, payload);
      },
      updateAffiliate: (id, formData) => {
        return Api.put(`v1/affiliate/${id}`, formData);
      },
      deleteAffiliate: (id) => {
        return Api.delete(`v1/affiliate/${id}`);
      },
      getMyAffiliate: (skip, limit) => {
        return Api.get(`v1/affiliate/my-affiliate?skip=${skip}&limit=${limit}`);
      },
    };
