import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import FooterBanner from "../FooterBanner";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../../slice/ListSlice";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";

function Blog() {
  const dispatch = useDispatch();
  const blogs = useSelector((store) => store?.ads?.blog);
  const blogData = blogs?.data || [];

  const itemsPerPage = 8;
  const totalDataCount = blogData?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substr(0, maxLength).trim() + "...";
    }
  }

  useEffect(() => {
    dispatch(
      getBlog({
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }, [dispatch, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  const defaultImageUrl =
    "https://media.istockphoto.com/id/1338011657/photo/the-word-blog-arranged-from-wooden-blocks-placed-on-a-white-computer-keyboard.jpg?b=1&s=170667a&w=0&k=20&c=MC6h9IhzFiWTFDOgeD1hsQQM5OJId6SWwVO8K7Fup-8=";

  return (
    <div>
      <Navbar />
      <FooterBanner title={"Blogs"} />
      <div className="w-full pt-4 lg:pt-5 pb-5 m-auto justify-center bg-white">
        <div className="w-11/12 grid grid-cols-1 lg:grid-cols-4 gap-2 lg:gap-5 overflow-hidden m-auto">
          {blogData?.items?.map((blog, index) => (
            <div key={index} className=" border-2 rounded-2xl shadow-md">
              <div className="max-w-md mx-auto bg-white rounded-xl overflow-hidden md:max-w-2xl">
                <img
                  className="object-cover object-center w-full h-64"
                  src={blog.image || defaultImageUrl}
                  alt={blog.title}
                />
                <div className="p-4">
                  <h2 className="text-2xl font-bold mb-4">{blog.title}</h2>
                  <p
                    className="text-gray-700"
                    dangerouslySetInnerHTML={{
                      __html: truncateText(blog.content, 80),
                    }}
                  />
                  <Link to={`/blog/${blog.slug}`}>
                    <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      READ MORE
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between py-6 m-auto max-w-[80%]">
          <div className="mt-2 text-lg">
            Showing{" "}
            <strong>
              {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
            </strong>{" "}
            of <strong>{totalDataCount}</strong> results.
          </div>
          <div className="flex justify-between gap-4 pt-6 lg:py-0">
            <button
              className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <GrFormPreviousLink className="text-xl hover:text-white" />
              PREVIOUS
            </button>
            <button
              className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage * itemsPerPage >= totalDataCount}
            >
              NEXT
              <GrFormNextLink className="text-xl hover:text-white" />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
