import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { createAffiliate } from "../../slice/AffiliateSLice";
import toast from "react-hot-toast";
import Subscription from "../Subscription";

function PostAffiliate({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialFormState = {
    title: "",
    image_url: null,
    position: "top",
    link: "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const [activeInput, setActiveInput] = useState(null);
  const [screen, setScreen] = useState("form");

  const handleInputFocus = (inputName) => {
    setActiveInput(inputName);
  };

  const handleInputBlur = () => {
    setActiveInput(null);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormState({
          ...formState,
          [name]: reader.result,
        });
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formState.title === "" || formState.link === "") {
      alert("Please fill in all required fields.");
      return;
    }
    setTimeout(() => {
      setScreen("pricing");
    }, 100);
  };
  const onSubmit = async (item) => {
    try {
      await dispatch(
        createAffiliate({
          formData: {
            ...formState,
            package: item,
            package_id: item.package_id,
          },
        })
      ).unwrap();
      toast.success("Your affiliate ads is created");
      navigate("/affiliate-ads");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Navbar />
      {screen === "form" && (
        <>
          <div
            className="w-full bg-cover bg-center"
            style={{ backgroundImage: `url(/img/form-bg-1.jpg)` }}
          >
            <div className="pt-32 lg:pt-20 w-full flex flex-col justify-center items-center gap-5 bg-black bg-opacity-50">
              <form
                className="w-11/12 md:w-6/12 bg-white p-4"
                onSubmit={handleSubmit}
              >
                <div className="full border-2 border-slate-300 shadow-lg p-4">
                  <div className="text-[#234777] text-4xl font-bold py-4">
                    Create Affiliate
                  </div>
                  <div>
                    <hr></hr>
                  </div>
                  <div className="w-full">
                    <div className="py-2 text-[#234777] font-semibold">
                      Title
                    </div>
                    <div>
                      <input
                        type="text"
                        name="title"
                        value={formState.title}
                        placeholder="Type Your Affiliate Title Here"
                        onFocus={() => handleInputFocus("title")}
                        onBlur={handleInputBlur}
                        onChange={handleChange}
                        className={`w-full p-2 py-2 border-b-2 border-gray-300 bg-slate-100 ${
                          activeInput === "title"
                            ? "border-b-2 border-blue-500"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="py-2 text-[#234777] font-semibold">
                      Add Image
                    </div>
                    <div>
                      <input
                        type="file"
                        name="image_url"
                        onChange={handleChange}
                        className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                        placeholder="Type Your Image Here"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="py-2 text-[#234777] font-semibold">
                      Link
                    </div>
                    <div>
                      <input
                        type="text"
                        name="link"
                        value={formState.link}
                        placeholder="Type Your Link Affiliate Here"
                        onFocus={() => handleInputFocus("link")}
                        onBlur={handleInputBlur}
                        onChange={handleChange}
                        className={`w-full p-2 py-2 border-b-2 border-gray-300 bg-slate-100 ${
                          activeInput === "link"
                            ? "border-b-2 border-blue-500"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div className="w-full flex gap-10 py-5 text-center">
                    <div className="flex-1 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                      <button
                        type="button"
                        className="flex w-full gap-2 justify-center items-center"
                        onClick={() => navigate(-1)}
                      >
                        <BsFillArrowLeftCircleFill />
                        Back
                      </button>
                    </div>
                    <div className="flex-1 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                      <button type="submit">SUBMIT</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </>
      )}
      {screen === "pricing" && (
        <Subscription
          data={formState}
          postType={"affiliate"}
          onBack={() => setScreen("form")}
          onSubmit={(item) => {
            onSubmit(item);
          }}
        />
      )}
    </div>
  );
}

export default PostAffiliate;
