import React from 'react';

function Loading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="border-t-4 border-blue-500 border-solid rounded-full h-16 w-16 animate-spin"></div>
      <h2 className="text-xl ml-2 text-gray-800">Loading...</h2>
    </div>
  );
}

export default Loading;
