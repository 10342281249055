import React, { useState } from "react";
import { CategoryTree, getCategoriesList } from "../slice/CategorySlice";
import {
  FaIndustry,
  FaShoppingBag,
  FaBuysellads,
  FaFighterJet,
  FaCreditCard,
  FaBalanceScale,
  FaCalendar,
  FaBuilding,
  FaBus,
  FaLaptop,
  FaTags,
  FaBook,
} from "react-icons/fa";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { PiFlagBanner } from "react-icons/pi";
import { MdCancel } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BiDesktop } from "react-icons/bi";

function PostNewAds() {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const categoryAdsData = useSelector((store) => store.categories.categoryList);
  const categoryAds = categoryAdsData?.data || [];
  React.useEffect(() => {
    dispatch(getCategoriesList({ is_parent: "yes" }));
  }, []);
  const getIcon = (iconname) => {
    switch (iconname) {
      case "fa-industry":
        return <FaIndustry />;
      case "fa-credit-card":
        return <FaCreditCard />;
      case "fa-fighter-jet":
        return <FaFighterJet />;
      case "fa-shopping-bag":
        return <FaShoppingBag />;
      case "fa-balance-scale":
        return <FaBalanceScale />;
      case "fa-calendar":
        return <FaCalendar />;
      case "fa-building":
        return <FaBuilding />;
      case "fa-bus":
        return <FaBus />;
      case "fa-laptop":
        return <FaLaptop />;
      case "fa-tags":
        return <FaTags />;
      case "fa-book":
        return <FaBook />;
      case "banner":
        return <PiFlagBanner />;
      case "affiliate":
        return <BiDesktop />;
      default:
        return <FaBuysellads />;
    }
  };
  return (
    <>
      <div className="w-full flex justify-center pt-10 pb-10">
        <div
          className="w-11/12 h-52 rounded-xl bg-cover bg-fixed flex flex-col sm:flex-row lg:flex-row xl:flex-row justify-around items-center"
          style={{ backgroundImage: "url(/img/post-new-bg.jpg)" }}
        >
          <div>
            <h1 className="text-4xl font-bold" style={{ color: "#234676" }}>
              Easy and quick!
            </h1>
          </div>
          <div>
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="text-xl text-white font-bold rounded-2xl px-8 py-2 bg-[#234676] hover:bg-slate-500"
            >
              {" "}
              POST NEW AD
            </button>
          </div>
        </div>
      </div>
      <Dialog
        open={showModal}
        as="div"
        onClose={() => setShowModal(false)}
        className="z-50 fixed inset-0 overflow-y-auto"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        <DialogPanel className="relative max-w-full max-h-full flex flex-col md:flex-row gap-5 rounded-lg space-y-2 bg-white bg-opacity-0 p-5">
          <span
            className="absolute right-5 top-16 text-3xl text-white cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <MdCancel />
          </span>
          <div className="bg-white w-10/12 relative top-24 m-auto flex flex-wrap gap-2 justify-between lg:justify-normal rounded p-5">
            {categoryAds.items?.map((item, index) => (
              <Link
                key={item.category_id}
                to={`/post/${item.slug}/${item.category_id}`}
                onClick={() => setShowModal(false)}
              >
                <div
                  key={index}
                  className="sm:w-[280px] lg:w-[265px] xl:w-[245px] relative flex rounded justify-center items-center mb-5"
                >
                  <div className="flex w-full items-center p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-slate-50">
                    <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                      {getIcon(item.icon)}
                    </div>
                    <div>
                      <p className="mb-2 text-xl font-medium text-gray-600">
                        {item.name}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
            <Link
              key={"banner"}
              to={`/postbanner`}
              onClick={() => setShowModal(false)}
            >
              <div className="sm:w-[280px] lg:w-[265px] xl:w-[245px] relative flex rounded justify-center items-center mb-5">
                <div className="flex w-full items-center p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-slate-50">
                  <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                    <PiFlagBanner />
                  </div>
                  <div>
                    <p className="mb-2 text-xl font-medium text-gray-600">
                      Banner
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link
              key={"affiliate"}
              to={`/postaffiliate`}
              onClick={() => setShowModal(false)}
            >
              <div className="sm:w-[280px] lg:w-[265px] xl:w-[245px] relative flex rounded justify-center items-center mb-5">
                <div className="flex w-full items-center p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-slate-50">
                  <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full">
                    <BiDesktop />
                  </div>
                  <div>
                    <p className="mb-2 text-xl font-medium text-gray-600">
                      Affiliate
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
}

export default PostNewAds;
