import React from "react";
import { FaFileDownload } from "react-icons/fa";

// Define handleDownload outside the component to avoid re-renders
const handleDownload = (id) => {
  alert(`Downloading Invoice ${id}`);
};

const Invoices = () => {
  const invoicesData = [
    { id: 1, title: "Invoice #001", amount: 199.0 },
    { id: 2, title: "Invoice #002", amount: 699.0 },
    { id: 3, title: "Invoice #003", amount: 299.0 },
    { id: 4, title: "Invoice #004", amount: 599.0 },
  ];

  return (
    <div className="bg-gray-100 ">
      <header className=" text-white pt-10 text-center">
        <h1 className="text-4xl text-[#234777] font-bold">INVOICES</h1>
      </header>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold mb-4">All Invoices</h2>
        {invoicesData.length === 0 ? (
          <div className="text-center py-8 text-2xl text-[#01C9DF]">No invoices found.</div>
        ) : (
          <div>
            {invoicesData.map((invoice) => (
              <div className="w-full bg-white p-8 shadow-md rounded-md my-4" key={invoice.id}>
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-semibold">{invoice.title}</h2>
                  <button
                    onClick={() => handleDownload(invoice.id)} 
                    className="flex items-center gap-3 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold px-5 py-3 rounded-3xl hover:bg-blue-600"
                  >
                    <FaFileDownload className="w-4 h-4"/>Download
                  </button>
                </div>
                <p className="text-gray-600">${invoice.amount}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Invoices;