import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackageList } from "../slice/PackageSlice";
import { FaCheck, FaTimes } from "react-icons/fa";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";

function Subscription({ data, postType, onSubmit, onBack }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const packageData = useSelector((store) => store.package.packageList);
  const packages = packageData?.data || [];

  useEffect(() => {
    dispatch(getPackageList());
  }, []);

  const getIconBasedOnValue = (value) => {
    return value === "yes" ? (
      <FaCheck style={{ color: "green" }} />
    ) : (
      <FaTimes style={{ color: "red" }} />
    );
  };

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showSummaryAndPayment, setShowSummaryAndPayment] = useState(false);
  const [amount, setAmount] = useState("0.00");
  // console.log("+++++++", packages);

  // Placeholder for payment method selection
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  // Placeholder for credit card form fields
  const [creditCardInfo, setCreditCardInfo] = useState({
    cardNumber: "",
    cardHolder: "",
    expirationDate: "",
    cvv: "",
  });

  const calculateTotals = (price) => {
    // Calculate subtotal, tax (20%), and total based on the selected package's price
    const currentSubtotal = price;
    const currentTax = currentSubtotal * 0.2; // 20% tax
    const currentTotal = currentSubtotal + currentTax;

    return { subtotal: currentSubtotal, tax: currentTax, total: currentTotal };
  };

  const handlePaymentMethodSelection = (method) => {
    setSelectedPaymentMethod(method);
  };

  const handleCreditCardInputChange = (e) => {
    const { name, value } = e.target;
    setCreditCardInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  // Placeholder for payment processing logic
  const handleFinish = () => {
    if (selectedPackage.price === 0) {
      // update subscription selected
      onSubmit(selectedPackage);
      // navigate("/");
    } else {
      navigate("/");
    }
    // Implement payment processing logic here
    // You may need to handle the credit card details
  };
  const selectPackage = (card) => {
    setAmount(`${card.price + card.price * 0.2}`);
    setSelectedPackage(card);

    setShowSummaryAndPayment(true);
  };

  const summaryAndPaymentContent = () => {
    if (!showSummaryAndPayment) return null;

    return (
      <div className="w-full max-w-[80%] flex m-auto flex-col gap-5 mt-4">
        <div className="float-left">
          <div className="flex items-center">
            <div className="text-md font-normal text-[#292929] flex-2 pr-3">
              SUMMARY
            </div>
            <hr className="flex-1" />
          </div>
          <div className="px-4 py-2 table">
            <ul className="w-full list-none table-cell min-w-[10vw]">
              <li className="w-full text-left border-b border-dashed border=-[#e1e1e1] py-2">
                Subtotal:{" "}
                <span className="float-right font-bold inline-block">
                  {" "}
                  ${selectedPackage.price}
                </span>
              </li>
              <li className="w-full text-left border-b border-dashed border=-[#e1e1e1] py-2">
                Tax (VAT 20%):{" "}
                <span className="float-right font-bold inline-block">
                  {" "}
                  ${selectedPackage.price * 0.2}
                </span>
              </li>
              <li className="w-full text-left  py-2">
                Total:{" "}
                <span className="float-right font-bold inline-block">
                  ${selectedPackage.price + selectedPackage.price * 0.2}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="gap-5">
          <div className="">
            <div className="flex items-center gap-2">
              <div className="text-md font-normal text-[#292929] flex-2 pr-3">
                PLEASE CHOOSE A PAYMENT METHOD
              </div>
              <hr className="flex-1" />
            </div>
            {selectedPackage.price !== 0 && (
              <PayPalScriptProvider
                options={{
                  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                }}
              >
                <PayPalButtons
                  key={amount}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: amount, // Replace with the actual amount
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={(data, actions) => {
                    onSubmit(selectedPackage);
                  }}
                  onError={(err) => {
                    console.error("PayPal Checkout onError", err);
                  }}
                />
              </PayPalScriptProvider>
            )}

            {/* <div className="flex items-center gap-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="creditCard"
                  checked={selectedPaymentMethod === "creditCard"}
                  onChange={() => handlePaymentMethodSelection("creditCard")}
                  className="form-radio h-5 w-5 text-green-400"
                />
                <span className="ml-2">Credit Card</span>
              </label>
            </div> */}
          </div>
          {/* {selectedPaymentMethod === "creditCard" && (
            <div className="flex flex-col mt-5">
              <div className="flex items-center">
                <div className="text-md font-normal text-[#292929] flex-2 pr-3">
                  CARD INFORMATION
                </div>
                <hr className="flex-1" />
              </div>
              <div className="px-4 py-2 max-w-[60%] m-auto ">
                <div className="mb-2">
                  <label htmlFor="cardNumber">Card Number:</label>
                  <input
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    value={creditCardInfo.cardNumber}
                    onChange={handleCreditCardInputChange}
                    className="w-full border rounded px-3 py-2"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="cardHolder">Cardholder Name:</label>
                  <input
                    type="text"
                    id="cardHolder"
                    name="cardHolder"
                    value={creditCardInfo.cardHolder}
                    onChange={handleCreditCardInputChange}
                    className="w-full border rounded px-3 py-2"
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="mb-2 w-1/2">
                    <label htmlFor="expirationDate">Expiration Date:</label>
                    <input
                      type="text"
                      id="expirationDate"
                      name="expirationDate"
                      value={creditCardInfo.expirationDate}
                      onChange={handleCreditCardInputChange}
                      className="w-full border rounded px-3 py-2"
                    />
                  </div>
                  <div className="mb-2 w-1/2">
                    <label htmlFor="cvv">CVV:</label>
                    <input
                      type="text"
                      id="cvv"
                      name="cvv"
                      value={creditCardInfo.cvv}
                      onChange={handleCreditCardInputChange}
                      className="w-full border rounded px-3 py-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>

        <div className="px-4 py-2 m-auto">
          {selectedPackage.price === 0 && (
            <button
              onClick={handleFinish}
              className="bg-[#09A7C4] text-white rounded-full text-xl font-semibold px-4 py-2 hover:text-[#01C6DA] hover:bg-white hover:border hover:border-[#01C6DA]"
            >
              FINISH
            </button>
          )}
          <button
            onClick={() => {
              onBack();
            }}
            className="bg-white border border-[#234777] text-[#234777] rounded-full text-xl font-semibold px-4 py-2 ml-2 hover:bg-[#234777] hover:text-white"
          >
            BACK TO FORM
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full mx-auto py-20">
      <div className="flex  flex-col align-middle text-center gap-3 py-5">
        <div className="text-3xl font-bold text-[#234777] ">Packages</div>
        <div className="text-md font-semibold text-[#1ABC9C]">
          Please take a few moments to choose the right package.
        </div>
        <div>
          <hr />
        </div>
      </div>
      <div className="w-full mx-auto">
        <div className="w-11/12 m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2">
          {packages.items?.map((card, index) => {
            const isSelected = selectedPackage === card;

            return (
              <div
                className={`bg-white w-full border shadow-xl rounded-lg overflow-hidden mx-2 my-4 mt-4 ${
                  isSelected ? "ring-2 ring-green-400 bg-green-50" : ""
                } `}
                key={index}
              >
                {card.recommended_sign === "yes" ? (
                  <div className="bg-[#1ABC9C] py-2">
                    <div className="text-center text-white font-semibold">
                      RECOMMENDED
                    </div>
                  </div>
                ) : (
                  <div className="bg-white py-4"></div>
                )}
                <div className="relative text-center px-4">
                  <div className="px-4 py-2">
                    <h2 className="text-2xl font-bold text-[#234777]">
                      {card.title}
                    </h2>
                  </div>
                  <hr />
                  <div className="table w-full">
                    <ul className="px-4 py-2 table-cell max-w-[200px] align-middle">
                      <li className="w-full text-left  border-b border-dashed border-[#e1e1e1] pr-2 py-2">
                        <span className="inline-block font-bold mr-3">
                          {card.auto_renewal}
                        </span>
                        Auto renewals
                      </li>
                      <li className="w-full text-left  border-b border-dashed border-[#e1e1e1] pr-2 py-2">
                        <span className="inline-block font-bold mr-3">
                          {card.promo_days}
                        </span>
                        Featured days
                      </li>
                      <li className="w-full text-left  border-b border-dashed border-[#e1e1e1] pr-2 py-2">
                        <span className="inline-block font-bold mr-3">
                          {card.pictures}
                        </span>
                        Amount of pictures
                      </li>
                      <li className="w-full text-left  border-b border-dashed border-[#e1e1e1] pr-2 py-2">
                        <span className="inline-block font-bold mr-3">
                          {getIconBasedOnValue(card.promo_show_at_top)}
                        </span>
                        Show featured label
                      </li>
                      <li className="w-full text-left  border-b border-dashed border-[#e1e1e1] pr-2 py-2">
                        <span className="inline-block font-bold mr-3">
                          {getIconBasedOnValue(card.promo_show_featured_area)}
                        </span>
                        Show in featured areas
                      </li>
                      <li className="w-full text-left  border-b border-dashed border-[#e1e1e1] pr-2 py-2">
                        <span className="inline-block font-bold mr-3">
                          {getIconBasedOnValue(card.promo_show_promoted_area)}
                        </span>
                        Show in promoted areas
                      </li>
                      <li className="w-full text-left  border-b border-dashed border-[#e1e1e1] pr-2 py-2">
                        <span className="inline-block font-bold mr-3">
                          {getIconBasedOnValue(card.promo_sign)}
                        </span>
                        Priority featured ad
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="w-full flex flex-col items-center gap-5">
                  <label
                    className={`form-radio h-5 w-5 text-white border border-slate-300 rounded cursor-pointer ${
                      isSelected
                        ? "form-radio h-5 w-5 text-green-400 border border-green-400 rounded cursor-pointer"
                        : "form-radio h-5 w-5 text-white border border-slate-300 rounded cursor-pointer"
                    } focus:ring-green-400 cursor-pointer relative`}
                  >
                    <input
                      type="radio"
                      name="selectedPackage"
                      checked={isSelected}
                      onChange={() => selectPackage(card)}
                      className="sr-only"
                    />
                    <span className="checkmark">
                      {isSelected && (
                        <FaCheck className="focus:ring-green-400 cursor-pointer text-green-400 font-extrabold text-xl" />
                      )}
                    </span>
                  </label>
                  <button className="w-2/3 px-8 py-1 bg-[#09A7C4] text-white rounded-3xl text-2xl font-semibold items-center">
                    ${card.price}
                  </button>
                </div>
                <hr className="mt-2" />
              </div>
            );
          })}
        </div>
        <div className="w-11/12 mx-2 mt-4">{summaryAndPaymentContent()}</div>
      </div>
    </div>
  );
}

export default Subscription;
