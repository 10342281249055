import React from "react";
import {
  BsInstagram,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import moment from 'moment';

function Footer() {
  return (
    <div className="w-full flex flex-col items-center text-white bg-[#234777] mt-auto">
      <div className="w-11/12 py-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-16">
        <div className="w-full ">
          <div className="w-full">
            <img src="/img/wwaLogoTransparantStroke.png" className="w-full" alt="" />
          </div>
          <div className="w-full py-5">
            World Wide Adverts, a leading digital marketing company dedicated to
            helping businesses and brands grow globally
          </div>
          <div className="w-2/3 flex justify-between">
            <div className="py-2 px-2 rounded-full bg-[#1873EB]">
              <a href="https://www.facebook.com/worldwideadverts.info/" target="blank"><FaFacebookF /></a>
            </div>
            <div className="py-2 px-2 rounded-full bg-[#d500c2]">
              <a href="https://www.instagram.com/worldadverts/" target="blank"><BsInstagram /></a>
            </div>
            <div className="py-2 px-2 rounded-full bg-[#199DF0]">
              <a href="https://twitter.com/worldwidead" target="blank"><BsTwitter /></a>
            </div>
            <div className="py-2 px-2 rounded-full bg-[#007AB5]">
              <a href="https://www.linkedin.com/company/world-wide-adverts/" target="blank"><FaLinkedinIn /></a>
            </div>
            <div className="py-2 px-2 rounded-full bg-[#F70101]">
              <a href="https://www.youtube.com/@worldwideadverts3670" target="blank"><BsYoutube /></a>
            </div>
          </div>
        </div>

        <div className="w-full ">
          <div className="text-2xl font-bold">Categories</div>
          <div className="py-4">
            <hr className="border-1 border-white"></hr>
          </div>
          <div className="w-full grid grid-cols-2 gap-12">
            <div className="w-full  font-medium">
              <Link to='/category/business'><div className="py-2">Business</div></Link>
              <Link to='/category/deals'><div className="py-2">Deals</div></Link>
              <Link to='/category/events'><div className="py-2">Events</div></Link>
              <Link to='/category/sale'><div className="py-2">For Sale</div></Link>
              <Link to='/category/it-tech'><div className="py-2">IT/Tech</div></Link>
              <Link to='/classified'><div className="py-2">Classified</div></Link>
            </div>

            <div className="w-full  font-medium">
              <Link to='/category/Jobs'><div className="py-2">Jobs</div></Link>
              <Link to='/category/Property'><div className="py-2">Property</div></Link>
              <Link to='/category/Resort-travel'><div className="py-2">Resort/Travel</div></Link>
              <Link to='/category/Services'><div className="py-2">Services</div></Link>
              <Link to='/category/Vehicle'><div className="py-2">Vehicle</div></Link>
              <Link to='/investment-category'><div className="py-2">Investment</div></Link>
            </div>
          </div>
        </div>

        <div className="w-full ">
          <div className="text-2xl font-bold">Help</div>
          <div className="py-4">
            <hr className="border-1 border-white"></hr>
          </div>
          <div className="w-full grid grid-cols-2 gap-12">
            <div className="w-full  font-medium ">
              <Link to='/help/ads-policies'><div className="py-2">Ads Policies</div></Link>
              <Link to='/help/terms-of-use'><div className="py-2">Terms of use</div></Link>
              <Link to='/help/terms-and-condition'><div className="py-2">Terms and condition</div></Link>
              <Link to='/help/privacy-policy'><div className="py-2">Privacy policy</div></Link>
              <Link to='/help/disclaimer'><div className="py-2">Disclaimer</div></Link>
            </div>

            <div className="w-full  font-medium ">
              <Link to='/help/cookie-policy'><div className="py-2">Cookie policy</div></Link>
              <Link to='/help/user-agreement'><div className="py-2">User agreement</div></Link>
              <Link to='/help/data-protection'><div className="py-2">Data protection</div></Link>
              <Link to='/help/laws-regulations'><div className="py-2"> Adverts policy</div></Link>
              <Link to='/help/help'><div className="py-2">Help</div></Link>
            </div>
          </div>
        </div>

        <div className="w-full ">
          <div className="text-2xl font-bold">About</div>
          <div className="py-4">
            <hr className="border-1 border-white"></hr>
          </div>
          <div className="w-full grid grid-cols-2 gap-12">
            <div className="w-full  font-medium">
              <Link  to='/about/company'><div className="py-2">Company</div></Link>
              <Link  to='/about/business'><div className="py-2">Business</div></Link>
              <Link  to='/about/intern-program'><div className="py-2">Intern program</div></Link>
              <Link  to='/about/contact'><div className="py-2">Contact</div></Link>
            </div>

            <div className="w-full  font-medium">
              <Link to='/about/about-us'><div className="py-2">About us </div></Link>
              <Link to='/about/career-with-us'><div className="py-2">Career with us </div></Link>
              <Link to='/about/developer'><div className="py-2">Developer</div></Link>
              <Link to='/blog'><div className="py-2">Blog</div></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <div>
          <hr className="border-1 border-white"></hr>
        </div>
        <div className="py-5">© World Wide Adverts 2017-{moment().format('YYYY')}</div>
      </div>
    </div>
  );
}

export default Footer;
