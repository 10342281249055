import React from "react";

const AccountSettings = () => {
  return (
    <div className="max-w-full mx-auto p-6 bg-white">
      <div className="text-xl font-bold mb-6 text-[#234777] text-center">
        Account Settings
      </div>

      <div className="mb-4">
        <label
          htmlFor="currentPassword"
          className="block text-base font-medium text-[#23478D]"
        >
          Current Password
        </label>
        <input
          type="password"
          required
          id="currentPassword"
          name="currentPassword"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          placeholder="Enter current password"
        />
        <p className="text-red-500 text-xs mt-1">
          Current Password cannot be blank.
        </p>
      </div>

      <div className="mb-4">
        <label
          htmlFor="newEmail"
          className="block text-base font-medium text-[#23478D]"
        >
          New Email Address
        </label>
        <input
          type="email"
          required
          id="newEmail"
          name="newEmail"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          placeholder="Enter new email"
        />
      </div>

      <div className="mb-4">
        <label className="block text-base font-medium text-[#23478D] mb-2">
          Notification Settings
        </label>

        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="likedAdverts"
            name="likedAdverts"
            className="mr-2"
          />
          <label htmlFor="likedAdverts" className="text-sm">
            Liked Adverts
          </label>
        </div>

        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="watchedAdverts"
            name="watchedAdverts"
            className="mr-2"
          />
          <label htmlFor="watchedAdverts" className="text-sm">
            Watched Adverts
          </label>
        </div>

        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="sharedAdverts"
            name="sharedAdverts"
            className="mr-2"
          />
          <label htmlFor="sharedAdverts" className="text-sm">
            Shared Adverts
          </label>
        </div>

        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="newAdverts"
            name="newAdverts"
            className="mr-2"
          />
          <label htmlFor="newAdverts" className="text-sm">
            New Adverts
          </label>
        </div>

        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="similarLocalAdverts"
            name="similarLocalAdverts"
            className="mr-2"
          />
          <label htmlFor="similarLocalAdverts" className="text-sm">
            Similar Local Adverts
          </label>
        </div>
      </div>

      <button className="bg-blue-500 text-white py-2 px-4 rounded-md">
        Save Changes
      </button>
    </div>
  );
};

export default AccountSettings;
