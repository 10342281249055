import React from "react";
import Navbar from "../Component/Navbar";
import ClassifiedAds from "../Component/ClassifiedAds";
import Footer from "../Component/Footer";
import BottomAds from "../Component/BottomAds";
import TopAds from "../Component/TopAds";

function NewAdsPage() {
  return (
    <div className="">
      <Navbar />
      <TopAds />
      <ClassifiedAds />
      <BottomAds />
      <Footer />
    </div>
  );
}

export default NewAdsPage;
