import React from 'react'
import Navbar from '../Component/Navbar'
import MyStoreAds from '../Component/MyStoreAds'
import Footer from '../Component/Footer'

function MyStore() {
  return (
    <div>
        <Navbar />
        <MyStoreAds />
        <Footer />
    </div>
  )
}

export default MyStore