import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import data from "../data/bottom-ads.json"

const BottomAds = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Slider {...settings}>
        {data.map((item, i) => (
            <Link to={item.link} target="_blank" className="mb-[-6px]"> 
            <img key={i} src={item.image} alt={item.link} style={{ width: "100%" }} /></Link>
        ))}
      </Slider>
    </>
  );
};
export default BottomAds;
