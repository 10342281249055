import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CategoryTreeChild, getCurrency } from "../slice/CategorySlice";
import { updateAds } from "../slice/ListSlice";
import toast from "react-hot-toast";
import { BiCheck } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditAdsOverlay.css";

function EditAdsOverlay({ onClose, data }) {
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    title: "",
    Image: null,
    video: null,
    category: "",
    currency: "Select Currency",
    price: "",
    description: "",
  });
  const [activeInput, setActiveInput] = useState(null);

  const categoryAdsData = useSelector((store) => store.categories.catTreeChild);
  const SubCatPost = categoryAdsData?.data || [];

  const catMasterData = useSelector((store) => store.categories.currency);
  const CatMaster = catMasterData?.data || [];

  useEffect(() => {
    if (data) {
      setFormState(data);
      dispatch(CategoryTreeChild({ id: data.category_id }));
    }
  }, [data]);
  const handleInputFocus = (inputName) => {
    setActiveInput(inputName);
  };

  const handleInputBlur = () => {
    setActiveInput(null);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormState({
        ...formState,
        [name]: files[0],
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formState.title === "" ||
      formState.category === "" ||
      formState.currency === "Select Currency" ||
      formState.price === "" ||
      formState.description === ""
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    if (formState.video && formState.Image) {
      alert("Please select either an image or a video, not both.");
      return;
    }
    if (parseFloat(formState.price) < 0) {
      alert("Price cannot be negative.");
      return;
    }
    try {
      await dispatch(
        updateAds({ adsId: formState.listing_id, formData: formState })
      ).unwrap();
      toast.success("Ads has been updated");
      onClose();
    } catch (error) {
      toast.error(error?.message || error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-8 rounded-md w-[80%] max-h-[90%] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Edit Ads Details</h2>
        <div className="">
          <form className="w-full bg-white" onSubmit={handleSubmit}>
            <div className="">
              <div className="text-[#234777] text-4xl font-bold py-4">
                {data?.category?.name}
              </div>
              <div>
                <hr></hr>
              </div>
              <div className="w-full flex gap-5">
                <div className="w-1/2">
                  <div className="py-2 text-[#234777] font-semibold">Title</div>
                  <div>
                    <input
                      type="text"
                      name="title"
                      value={formState.title}
                      placeholder="Type Your Product Title Here"
                      onFocus={() => handleInputFocus("title")}
                      onBlur={handleInputBlur}
                      onChange={handleChange}
                      className={`w-full p-2 py-2 border-b-2 border-gray-300 bg-slate-100 ${
                        activeInput === "title"
                          ? "border-b-2 border-blue-500"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="py-2 text-[#234777] font-semibold">
                    SUB CATEGORY
                  </div>
                  <div className="w-full">
                    <select
                      name="category"
                      value={formState.category}
                      onChange={handleChange}
                      className="w-full p-2 py-[0.6rem] bg-slate-100 border-b-2 border-gray-300"
                    >
                      <option className="text-slate-600">
                        Select Sub Category"
                      </option>
                      {SubCatPost.items &&
                        SubCatPost.items[0]?.childs?.map((subCat, i) => {
                          return (
                            <option key={i} value={subCat.name}>
                              {subCat.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">
                  Add Image
                </div>
                <div>
                  <input
                    type="file"
                    name="Image"
                    onChange={handleChange}
                    className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                    placeholder="Type Your Product Title Here"
                  />
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-gray-500 font-semibold">
                  Add Video
                </div>
                <div className="bg-slate-200">
                  <input
                    type="file"
                    name="video"
                    onChange={handleChange}
                    className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                    accept="video/*"
                  />
                </div>
              </div>
              <div className="w-full gap-5 flex justify-between">
                <div className="w-1/2">
                  <div className="py-2 text-[#234777] font-semibold">
                    Select Currency
                  </div>
                  <div className="w-full">
                    <select
                      name="currency"
                      value={formState.currency}
                      onChange={handleChange}
                      className="w-full p-2 py-[0.6rem] bg-slate-100 border-b-2 border-gray-300"
                    >
                      <option className="text-slate-600">
                        Select Currency
                      </option>
                      {CatMaster.items &&
                        CatMaster.items.map((currency, i) => {
                          return (
                            <option key={i} value={currency.name}>
                              {currency.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="py-2 text-[#234777] font-semibold">Price</div>
                  <div>
                    <input
                      type="number"
                      name="price"
                      min="0"
                      value={formState.price}
                      onChange={handleChange}
                      className="w-full p-2 py-2 bg-slate-100 border-b-2 border-gray-300"
                      placeholder="Enter Your Pricing Amount"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="py-2 text-[#234777] font-semibold">
                  Ad Description
                </div>
                <div>
                  {/* <textarea
                    name="description"
                    value={formState.description}
                    onChange={handleChange}
                    rows="4"
                    cols="50"
                    className="w-full p-4 bg-slate-100 border-b-2 border-gray-300"
                    placeholder="Describe Your Message"
                  /> */}
                  <ReactQuill
                    value={formState.description}
                    onChange={(value) => {
                      setFormState({
                        ...formState,
                        description: value,
                      });
                    }}
                    modules={EditAdsOverlay.modules}
                    formats={EditAdsOverlay.formats}
                    className="react-quill-editor"
                  />
                </div>
              </div>
              <div className="w-full flex justify-end gap-5 py-5 text-center">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-3 py-2 text-base font-medium gap-2 text-center inline-flex items-center text-gray-900 bg-white rounded-lg border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
                >
                  <MdOutlineClose />
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-3 py-2 text-base font-medium gap-2 text-center inline-flex items-center text-white bg-blue-900 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 "
                >
                  <BiCheck />
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
EditAdsOverlay.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
};

// Quill formats to control which formats are allowed in the editor
EditAdsOverlay.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
export default EditAdsOverlay;
