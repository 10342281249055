import React from "react";

function Fiverr() {
  return (
    <div className="w-full bg-slate-200 py-2 h-[620px] flex flex-col items-center ">
   <div className=" text-center">
      <h1 className="text-[#234777] font-bold text-2xl py-8 lg:text-4xl">Investment Category</h1>
   </div>

      <div className="w-11/12 h-full">
        <iframe
          className="w-full h-full"
          src="https://www.fiverr.com/embed_gigs?id=U2FsdGVkX19cEmkUXq9CTjmBCr8gLarXezhVzraj/fLLTt1ZHGavVHl363y3B5w85aWcRWiIJpCkamz36cxdv0Ujz2Wl3alUg5/mz6NzyiMdj0fl75KIFZIrdTEbcWZlPDxYy5Jz0lOs1e1KnirZjek0I1YrchI1MGLiOztiz941ONTT/LuoesLfuBMREulY3pId6BB1928luYBtvly/aS8MnJwUfLUpQn3RLR8Q/Bl5M6nQ28TQ3U6EaUO/3Wn2lRBfr0rImSxvwjdEbld4pScxKtDbbuLrnWf4jTA7pLV03oWO2kXiLhxcnI+1qgtPbYk66lypwd+4PajFMJ1/aYd6Zpfil6BI49TKcZGGekiPZxySDjIaj8/3T+Y1/wJr78Fuif2KxXMnAvHEcjDeSaSrkDgurV/swVu4lajutZpERb3A4Bdo3fx3ssohd/omE5/tdMAc8sdfVG11mM/FaQ==&affiliate_id=263951&strip_google_tagmanager=true"
        ></iframe>
      </div>
    </div>
  );
}

export default Fiverr;
