import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BannerServices from "../services/BannerServices";

const initialState = {
  loading: false,
  bannerList: [],
  myBannerList: [],
  message: null,
  error: null,
};
export const getBannerList = createAsyncThunk(
  "banner/getBannerList",
  async ({ skip, limit, sort_type, user_id }) => {
    const res = await BannerServices.getBannerList({
      skip,
      limit,
      sort_type,
      user_id,
    });
    return res.data;
  }
);
export const createBanner = createAsyncThunk(
  "banner/createBanner",
  async ({ formData }) => {
    const res = await BannerServices.createBanner(formData);
    return res;
  }
);
export const updateBanner = createAsyncThunk(
  "banner/updateBanner",
  async ({ Id, formData }) => {
    const res = await BannerServices.updateBanner(Id, formData);
    return res;
  }
);
export const deleteBanner = createAsyncThunk(
  "banner/deleteBanner",
  async (Id) => {
    const res = await BannerServices.deleteBanner(Id);
    return res;
  }
);
export const getMyBanner = createAsyncThunk(
  "banner/getMyBanner",
  async ({ skip, limit, sort_type }) => {
    const res = await BannerServices.getMyBanner({ skip, limit, sort_type });
    return res.data;
  }
);

const handleError = (state, action) => {
  state.error = action.error.message;
};
const BannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    redirectFalse: (state) => {
      state.redirect = false;
    },
    clearBannerErrorAndMessage: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: {
    [getBannerList.pending]: (state) => {
      state.loading = true;
      state.bannerList = [];
    },
    [getBannerList.fulfilled]: (state, action) => {
      state.bannerList = action.payload;
      state.loading = false;
      state.error = null;
    },
    [getBannerList.rejected]: handleError,

    [createBanner.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [createBanner.rejected]: handleError,

    [updateBanner.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [updateBanner.rejected]: handleError,

    [deleteBanner.fulfilled]: (state, action) => {
      state.message = action.payload.message;
    },
    [deleteBanner.rejected]: handleError,

    [getMyBanner.pending]: (state) => {
      state.loading = true;
      state.myBannerList = [];
    },
    [getMyBanner.fulfilled]: (state, action) => {
      state.myBannerList = action.payload;
      state.loading = false;
      state.error = null;
    },
    [getMyBanner.rejected]: handleError,
  },
});
export const { clearBannerErrorAndMessage, redirectFalse } =
  BannerSlice.actions;
const { reducer } = BannerSlice;
export default reducer;
