import React from 'react'
import Navbar from '../Component/Navbar'
import SponsoredAds from '../Component/SponsoredAds';
import Footer from '../Component/Footer';
import BottomAds from '../Component/BottomAds';
import TopAds from "../Component/TopAds";

function PromotedAdsPage() {
  return (
    <div className=''>
       <Navbar />
       <TopAds />
       <SponsoredAds />
       <BottomAds />
       <Footer />
    </div>
  )
}

export default PromotedAdsPage;