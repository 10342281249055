import React from 'react'
import Navbar from '../Component/Navbar'
import Classified from '../Component/Classified'
import Footer from '../Component/Footer'
import BottomAds from '../Component/BottomAds'

function ClassifiedPage() {
  return (
    <div>
      <Navbar />
      <Classified />
      <BottomAds />
      <Footer />
    </div>
  )
}

export default ClassifiedPage
