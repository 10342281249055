/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateListTop } from "../slice/AffiliateSLice";
import { Link } from "react-router-dom";

const TopAffiliate = () => {
  const dispatch = useDispatch();
  const affiliateData = useSelector((store) => {
    return store.aff.affiliateListTop;
  });
  const affiliates = affiliateData?.data || [];
  useEffect(() => {
    dispatch(getAffiliateListTop());
  }, []);
  return (
    <div className="w-full flex justify-center py-2">
      <div className="container mx-auto w-11/12 relative  ">
        <div className="">
          <div className="flex justify-between">
            <h1 className="text-blue-500 font-bold text-2xl">
              <span className="text-[#234777]">Top</span>
              <span className="text-[#01C6DA]"> Affiliates</span>
            </h1>
          </div>
          <div className="">
            {affiliates?.items?.slice(0, 6).map((items, index) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={`/affiliate?url=${items.link}`}
                key={index}
                className="p-4 max-w-sm"
              >
                {items.image_url ? (
                  <div
                    key={index}
                    className="group w-full h-full overflow-hidden border border-slate-200 shadow-lg rounded-lg"
                  >
                    <div className="h-40 mb-2">
                      {items.image_url ? (
                        <img
                          className="transform duration-500 hover:scale-105 h-full w-full object-cover"
                          src={items.image_url}
                          alt={items.title}
                          onError={(e) => {
                            e.target.src = "/img/no-image.png";
                          }}
                        />
                      ) : (
                        <img
                          className="transform duration-500 hover:scale-105 h-full w-full object-cover"
                          src={"/img/no-image.png"}
                          alt="Default"
                        />
                      )}
                    </div>
                    <div className="px-4">
                      <div className="text-base text-[#232D3B] py-1 flex-grow h-[3.5rem]">
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to={`/affiliate?url=${items.link}`}
                          className="text-black group-hover:text-yellow-500 inline-flex items-center"
                        >
                          <span className="line-clamp-2">{items.title}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="group w-full rounded-lg h-full bg-gradient-to-r from-blue-900 to-cyan-700 shadow-lg">
                    <div className="h-[226px] p-8">
                      <div className="flex flex-grow h-full items-center justify-center">
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to={`/affiliate?url=${items.link}`}
                          className="text-base text-white text-ellipsis overflow-hidden group-hover:text-yellow-500 line-clamp-6"
                        >
                          {items.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopAffiliate;
