import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../slice/AuthSlice";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((store) => store.auth?.userDetail?.data);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const validateForm = async () => {
    // Reset errors
    setCurrentPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");

    // Validate Current Password
    if (currentPassword === "") {
      setCurrentPasswordError("Current Password cannot be blank.");
      return;
    }

    // Validate New Password
    if (newPassword === "") {
      setNewPasswordError("Password cannot be blank.");
      return;
    }

    // Validate Confirm New Password
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm Password cannot be blank.");
      return;
    }

    // Check if New Password and Confirm New Password match
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }

    // Check if New Password meets length requirement
    if (newPassword.length < 6) {
      setNewPasswordError("Password must be at least 6 characters.");
      return;
    }

    // If all validations pass, you can submit the form or perform other actions
    // For demonstration purposes, alert a success message
    try {
      setLoading(true);
      await dispatch(
        resetPassword({
          email: userDetails.email,
          password: newPassword,
          password_confirmation: confirmPassword,
        })
      ).unwrap();
      toast.success("Password changed successfully!");
      setLoading(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };
  return (
    <div className="max-w-full mx-auto bg-white p-6">
      <div className="text-xl text-center text-[#234777] font-bold mb-4">
        Change Password
      </div>

      {/* Current Password */}
      <div className="mb-4">
        <label
          htmlFor="currentPassword"
          className="block text-base font-medium text-[#234777]"
        >
          Current Password
        </label>
        <input
          type="password"
          required
          id="currentPassword"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Enter current password"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        />
        <p className="text-red-500 text-sm mt-1">{currentPasswordError}</p>
      </div>

      {/* New Password */}
      <div className="mb-4">
        <label
          htmlFor="newPassword"
          className="block text-base font-medium text-[#234777]"
        >
          New Password
        </label>
        <input
          type="password"
          required
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        />
        <p className="text-red-500 text-sm mt-1">{newPasswordError}</p>
      </div>

      {/* Confirm New Password */}
      <div className="mb-6">
        <label
          htmlFor="confirmPassword"
          className="block text-base font-medium text-[#234777]"
        >
          Confirm New Password
        </label>
        <input
          type="password"
          required
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Reenter new password"
          className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        />
        <p className="text-red-500 text-sm mt-1">{confirmPasswordError}</p>
      </div>

      {/* Save Changes Button */}
      <div className="flex justify-center">
        <button
          disabled={loading}
          onClick={validateForm}
          className="bg-blue-500 text-white p-2 px-4 font-semibold rounded-md hover:bg-blue-600"
        >
          {loading ? (
            <div className="flex items-center">
              <ClipLoader size={20} color="#fff" />
              <span className="ml-2">Loading...</span>
            </div>
          ) : (
            "Save Changes"
          )}
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
