import React from "react";
import { Link } from "react-router-dom";

function Cards() {
  const data = [
    {
      title: "Sponsored Ads",
      description:
        "Sponsored ads content can be an effective way for businesses to reach their target audience in a less intrusive manner.",
      link: "/sponsored-ads",
      image: "/img/ads-banner-1.png",
    },
    {
      title: "Classifieds Ads",
      description:
        "Classifieds ads content can be an effective way for businesses to reach their target audience in a less intrusive manner.",
      link: "/classifieds-ads",
      image: "/img/ads-banner-2.png",
    },
    {
      title: "Affiliate Ads",
      description:
        "Affiliate ads content can be an effective way for businesses to reach their target audience in a less intrusive manner.",
      link: "/affiliate-ads",
      image: "/img/ads-banner-3.png",
    },
    {
      title: "Featured Ads",
      description:
        "Featured ads content can be an effective way for businesses to reach their target audience in a less intrusive manner.",
      link: "/featured-ads",
      image: "/img/ads-banner-5.png",
    },
    {
      title: "Promoted Ads",
      description:
        "Promoted ads content can be an effective way for businesses to reach their target audience in a less intrusive manner.",
      link: "/promoted-ads",
      image: "/img/ads-banner-4.jpg",
    },
    {
      title: "Banner Adverts",
      description:
        "Banner adverts content can be an effective way for businesses to reach their target audience in a less intrusive manner.",
      link: "/banner",
      image: "/img/ads-banner-6.jpg",
    },
  ];
  return (
    <div className="w-full  flex justify-center pt-12 mb-10">
      {/* <div className="w-11/12 flex flex-wrap justify-center gap-5 sm:flex sm:flex-row sm:justify-between lg:flex-nowrap xl:flex-nowrap"> */}
      <div className="w-10/12 grid grid-cols-1 justify-center gap-8 md:grid-cols-3 lg:grid-cols-3">
        {data.map((item, i) => (
          <div
            key={i}
            className="w-full flex flex-col overflow-hidden rounded-lg sm:w-full shadow-xl relative"
          >
            <div className="">
              <img src={item.image} className="w-full h-52" alt=""></img>
            </div>
            <div className="px-2 text-center flex flex-1 flex-col justify-between flex-grow">
              <div className="pt-2">
                <h1 className="text-lg font-bold text-slate-700">
                  {item.title}
                </h1>
              </div>
              <div className="py-2 text-slate-600">
                <p className="text-sm">{item.description}</p>
              </div>
              <div className="py-2 mb-1">
                <Link to={item.link}>
                  <button className="text-white text-sm font-bold rounded-md bg-[#234777] py-2 px-12 hover:bg-[#0044BB] transition duration-300 hover:text-white">
                    See All
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
