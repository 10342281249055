import React from 'react'
import Navbar from '../Navbar'
import FooterBanner from '../FooterBanner'
import Footer from '../Footer'

function AboutBusiness() {
  return (
    <div>
      <Navbar  />
      <FooterBanner title={'Business'} />
      <div className="w-full pt-10 flex justify-center">
        <div className="w-10/12">
          {/* <div className="w-full flex justify-center text-center">
            <div className="text-4xl font-bold pt-2 pb-4 text-blue-950">
            Business
            </div>
          </div>
          <div className="bg-blue-400">
            <hr className="border border-cyan-400"></hr>
          </div> */}
          <div className="">
                
                <div className="pt-4 pb-4">World Wide Adverts is a globally recognized provider of world-class services, dedicated to helping customers achieve growth and prosperity. Our primary objective is to create awareness for your products and services while expanding your market reach. With us by your side, businesses of all types and sizes, be it small, medium, large, new, or established, can soar to greater heights in the global marketplace.</div>
                <div className="pt-4 pb-4">At World Wide Adverts, we are committed to ensuring that products, services, jobs, business partnerships, and investments are accessible on a global scale, regardless of regional or societal barriers. Our unwavering aim is to foster inclusivity and provide equal opportunities for all. By harnessing the power of our platform, you can unlock a world of possibilities.</div>
                <div className="pt-4 pb-4">As your one-stop marketplace and gateway to the rest of the world, World Wide Adverts takes pride in driving your business expansion, global recognition, and profit growth. Our user-friendly website, <span className='text-cyan-600'>https://worldwideadverts.info/</span>, serves as a hub for seamless transactions. Additionally, our app, available for download on both IOS and Android devices, empowers you to access our services conveniently on the go.</div>
                <div className="pt-4 pb-4">We recognize that your success is intertwined with ours. Our dedicated team of professionals works tirelessly to ensure exceptional results for your products and services. Whether you are selling, buying, or offering services, we provide tailored resources and strategies to maximize your potential.</div>
                <div className="pt-4 pb-4">Embark on your journey to success with World Wide Adverts. Allow us to help you unleash the full potential of your products and services. Join our platform today and experience unparalleled business expansion, global recognition, and profit growth. Remember, your triumph is our driving force, and together, we can achieve remarkable success. Trust us to deliver the best for your business, and let us embark on this transformative journey together.</div>
                
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AboutBusiness
