import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "./MultiSelectDropdown";
import DataDummy from "../data/dummy-banner.json";
import BannerDetail from "./BannerDetail";
import { getBannerList } from "../slice/BannerSlice";
import { Gallery } from "react-grid-gallery";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const optionsSize = [
  {
    Size: "300×250",
    Width: 300,
    Height: 250,
    Name: "Medium Rectangle - 300×250",
    Frequency: "40%",
  },
  {
    Size: "728×90",
    Width: 728,
    Height: 90,
    Name: "Leaderboard - 728×90",
    Frequency: "25%",
  },
  {
    Size: "160×600",
    Width: 160,
    Height: 600,
    Name: "Wide Skyscraper - 160×600",
    Frequency: "12%",
  },
  {
    Size: "300×600",
    Width: 300,
    Height: 600,
    Name: "Half Page - 300×600",
    Frequency: "5%",
  },
  {
    Size: "970×250",
    Width: 970,
    Height: 250,
    Name: "Billboard - 970×250",
    Frequency: "1%",
  },
  {
    Size: "336×280",
    Width: 336,
    Height: 280,
    Name: "Large Rectangle - 336×280",
    Frequency: "<1%",
  },
  {
    Size: "468×60",
    Width: 468,
    Height: 60,
    Name: "Banner - 468×60",
    Frequency: "<1%",
  },
  {
    Size: "234×60",
    Width: 234,
    Height: 60,
    Name: "Half Banner - 234×60",
    Frequency: "<1%",
  },
  {
    Size: "120×600",
    Width: 120,
    Height: 600,
    Name: "Skyscraper - 120×600",
    Frequency: "<1%",
  },
  {
    Size: "120×240",
    Width: 120,
    Height: 240,
    Name: "Vertical Banner - 120×240",
    Frequency: "<1%",
  },
  {
    Size: "300×1050",
    Width: 300,
    Height: 1050,
    Name: "Portrait - 300×1050",
    Frequency: "<1%",
  },
  {
    Size: "970×90",
    Width: 970,
    Height: 90,
    Name: "Large Leaderboard - 970×90",
    Frequency: "<1%",
  },
  {
    Size: "250×250",
    Width: 250,
    Height: 250,
    Name: "Square - 250×250",
    Frequency: "<1%",
  },
  {
    Size: "200×200",
    Width: 200,
    Height: 200,
    Name: "Small Square - 200×200",
    Frequency: "<1%",
  },
  {
    Size: "180×150",
    Width: 180,
    Height: 150,
    Name: "Small Rectangle - 180×150",
    Frequency: "<1%",
  },
  {
    Size: "125×125",
    Width: 125,
    Height: 125,
    Name: "Button - 125×125",
    Frequency: "<1%",
  },
];
const useMediaQuery = () => {
  const [screenSize, setScreenSize] = useState("2xl");

  const checkScreenSize = () => {
    const width = window.innerWidth;
    if (width < 640) {
      setScreenSize("xs");
    } else if (width >= 640 && width < 768) {
      setScreenSize("sm");
    } else if (width >= 768 && width < 1024) {
      setScreenSize("md");
    } else if (width >= 1024 && width < 1280) {
      setScreenSize("lg");
    } else if (width >= 1280 && width < 1536) {
      setScreenSize("xl");
    } else {
      setScreenSize("2xl");
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return screenSize;
};

const BannerList = () => {
  const dispatch = useDispatch();
  const categoryAdsData = useSelector((store) => store.categories.categoryList);
  const bannerListData = useSelector((store) => store.banner.bannerList);
  const { loading } = useSelector((store) => store.banner);

  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [data, setData] = useState([]);
  const [dataNew, setDataNew] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [page, setPage] = useState(1);
  const limit = 20;
  const sort_type = "desc";
  const [isOpen, setIsOpen] = useState(false);

  const loader = useRef(null);
  useEffect(() => {
    const options = categoryAdsData?.data?.items.map((o) => {
      {
        return {
          CategoryID: o.category_id,
          Name: o.name,
        };
      }
    });
    setOptionsCategory(options);
    return () => {};
  }, [categoryAdsData]);

  // useEffect(() => {
  //   // console.log(data);
  //   return () => {};
  // }, [data]);
  useEffect(() => {
    const skip = (page - 1) * limit;
    dispatch(getBannerList({ skip, limit, sort_type }));
  }, [page, dispatch]);

  const screenSize = useMediaQuery();
  useEffect(() => {
    if (bannerListData?.data?.items?.length) {
      // let wrappedArrays = [[], [], [], [], []];
      // let divide = 5;
      // switch (screenSize) {
      //   case "xl":
      //     wrappedArrays = [[], [], [], []];
      //     divide = 4;
      //     break;
      //   case "lg":
      //     wrappedArrays = [[], [], []];
      //     divide = 3;
      //     break;
      //   case "md":
      //     wrappedArrays = [[], []];
      //     divide = 2;
      //     break;
      //   case "sm":
      //     wrappedArrays = [[]];
      //     divide = 1;
      //     break;
      //   default:
      //     break;
      // }
      // bannerListData?.data?.items.forEach((item, index) => {
      //   const size = item.size_img.split("x");
      //   wrappedArrays[index % divide].push({
      //     ...item,
      //     width: parseInt(size[0]),
      //     height: parseInt(size[1]),
      //   });
      // });
      // setData([...wrappedArrays]);
      const dataMap = bannerListData?.data?.items.map((item) => {
        // const size = item.size_img?.split("x");

        return {
          ...item,
          src: item.img,
          // width: size ? parseInt(size[0]) : 0,
          // height: parseInt(size ? size[1] ?? size[0] : 0),
        };
      });
      // console.log(dataMap);
      setDataNew([...dataNew, ...dataMap]);
    }
  }, [bannerListData]);
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (
  //         entries[0].isIntersecting &&
  //         !loading &&
  //         bannerListData?.data?.items?.length.length <
  //           bannerListData?.data?.total
  //       ) {
  //         setPage((prev) => prev + 1);
  //       }
  //     },
  //     { threshold: 1 }
  //   );

  //   if (loader.current) {
  //     observer.observe(loader.current);
  //   }

  //   return () => {
  //     if (loader.current) {
  //       observer.unobserve(loader.current);
  //     }
  //   };
  // }, [loader, loading, bannerListData?.data?.items?.length]);
  return (
    <div className="relative pt-32 pb-10 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col sm:flex-row sm:flex justify-between py-5"></div>
      <div className="p-10 bg-gray-200 rounded-2xl flex justify-between items-center mb-5">
        <div className="flex-1">
          <h1 className="text-blue-500 font-bold text-4xl mb-5">
            <span className="text-[#234777]">Banner</span>
            <span className="text-[#01C6DA]"> Adverts</span>
          </h1>
          <p>
            Maximize your website's revenue potential by integrating banner
            affiliate ads, allowing you to earn commissions for every click or
            conversion generated through your eye-catching advertisements.
          </p>
        </div>
        <div className="flex flex-1 justify-end">
          <img
            className="transform duration-500 hover:scale-105 w-[250px] object-cover"
            src="/img/ilustration-banner.png"
            alt="header"
          />
        </div>
      </div>
      {/* <div className="flex items-center gap-5 mb-5 ring-1 ring-black ring-opacity-5 rounded-2xl p-2 px-5">
        <h1>Filter:</h1>
        <MultiSelect
          options={optionsSize}
          onSelect={(selected) => {
            setSelectedSize(selected);
          }}
          label="Filter by Size"
        />
        <MultiSelect
          options={optionsCategory}
          onSelect={(selected) => {
            setSelectedCategory(selected);
          }}
          label="Filter by Category"
        />
      </div> */}
      {/* <section className="p-5 md:p-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 items-start ">
        {data.map((colums, i) => (
          <div key={i} className="grid grid-cols-1 gap-4">
            {colums.map((item, ii) => (
              <button
                onClick={() => {
                  setSelectedData(item);
                  setIsOpen(true);
                }}
                key={ii}
              >
                <div key={ii} className="rounded overflow-hidden shadow-lg">
                  {item.height > item.width ? (
                    <img
                      className={`w-full h-[400px] object-cover`}
                      src={item.img}
                      alt={item.title}
                    />
                  ) : (
                    <img
                      className="w-full h-[200px] object-fill"
                      src={item.img}
                      alt={item.title}
                    />
                  )}
                  <div className="px-6 py-4">
                    <div className="text-base">{item.title}</div>
                    <p className="text-gray-600 text-sm">
                      Created by {item.created_by}
                    </p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        ))}
      </section> */}
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow flex flex-col justify-between">
          <InfiniteScroll
            dataLength={dataNew.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={true}
            loader={
              <div ref={loader} style={{ height: "200px" }}>
                {loading && (
                  <div className="flex gap-5">
                    <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                      <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                        <svg
                          className="w-10 h-10 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                      <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                        <svg
                          className="w-10 h-10 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
                      <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded sm:w-96">
                        <svg
                          className="w-10 h-10 text-gray-200"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              // <h1>loading...</h1>
            }
          >
            {/* <Gallery
          images={dataNew}
          enableImageSelection={false}
          onClick={(_, item) => {
            setSelectedData(item);
            setIsOpen(true);
          }}
          margin={5}
        /> */}
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4 }}
            >
              <Masonry gutter="20px">
                {dataNew.map((item, i) => (
                  <button
                    onClick={() => {
                      setSelectedData(item);
                      setIsOpen(true);
                    }}
                    key={i}
                    className="w-full block overflow-hidden"
                  >
                    <img
                      className="w-full rounded-md h-auto max-h-[500px] object-cover"
                      src={item.img}
                      alt={item.title}
                    />
                  </button>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </InfiniteScroll>
        </div>
      </div>
      <BannerDetail isOpen={isOpen} setIsOpen={setIsOpen} data={selectedData} />
    </div>
  );
};
export default BannerList;
