import React from "react";
import Navbar from "../Navbar";
import FooterBanner from "../FooterBanner";
import Footer from '../Footer'

function PrivacyPolicy() {
  return (
    <div>
      <Navbar />
      <FooterBanner title={'PRIVACY POLICY'} />
      <div className="w-full pt-10 flex justify-center">
        <div className="w-10/12">
        
          <div className="">
          <div className="pt-4 pb-4">PRIVACY POLICY FOR WORLDWIDE ADVERTS</div>
          <div className="pt-2 pb-2">
              This Privacy Policy governs the manner in which Worldwide
              Advertscollects, uses, maintains and discloses information
              collected from users of WorldwideAdverts.info.Worldwide Adverts
              complies with all applicable laws and requirements for user
              privacy. Throughout this Policy the words “we,” “us,” and “our”
              refer to Worldwide Adverts. If you feel that we are not abiding by
              this privacy policy, you should contact us via email at
              worldwideadvertsinfo@gmail.com.
            </div>
            <div className="pt-4 pb-4">PERSONAL IDENTIFICATION INFORMATION</div>
            <div className="pt-2 pb-2">
              We may collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our site, fill out a form, register on our site and in connection
              with other activities, features or resources we make available on
              our Site. Users may be asked for, as appropriate, name, email
              address and phone number. We will collect personal identification
              information from Users only if they voluntarily submit such
              information to us. Users can always refuse to supply personally
              identification information, except that it may prevent them from
              engaging in certain Site related activities. Camera, photo and
              contact access will be requested for the app functionality as
              these features will allow proper app and website functionality.
              The website worldwiedsadverts.info requires this information to
              allow posting of adverts, services and full use of the site. The
              provided information will be used in accordance with the European
              Data protection GDPR, Data Protection 2018 Laws and Regulations
              USA ICLG in safeguarding your privacy.No information will be sold
              or traded all collected data will be used in accordance with
              privacy guidelines set out and for the purposes of the website/app
              functionality. All Data collected will be used for purposes of the
              website/app and will only be used within our group of companies
              and for the purposes of the functionality of the website/app.
            </div>

            <div className="pt-4 pb-4">NON-PERSONAL INFORMATION</div>
            <div className="pt-2 pb-2">
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our
              Sites, such as the operating system and the Internet service
              providers utilized and other similar information. The information
              is essential in the running of our websites/apps and giving a good
              user experience and will also help us improve the functionality of
              our websites/apps. The Data collected will be for internal use and
              the improvement of our sites.
            </div>

            <div className="pt-4 pb-4">USE OF INFORMATION</div>
            <div className="pt-2 pb-2">
              The site worldwideadverts.info collects and uses Users personal
              information for the following purposes:
            </div>
            <div className="pt-2 pb-2">To personalize user experience</div>
            <div className="pt-2 pb-2">
              We may use information in the aggregate to understand how our
              Users as a group or individuals use the services and resources
              provided on our Site. We also use the gathered data to improve
              user experience and help us improve the functionality and service
              of the website/app.
            </div>
            <div className="pt-4 pb-4">To improve our Site</div>
            <div className="pt-2 pb-2">
              We continually strive to improve our website offerings based on
              the information and feedback we receive from you. Gathered
              information personal and none personal will only be used to
              improve the website/app delivery of service and improved
              functionality.
            </div>

            <div className="pt-4 pb-4">To send periodic emails</div>
            <div className="pt-2 pb-2">
              The email address Users provide may be used to respond to their
              inquiries, and/or other requests or questions. If User decides to
              opt-in to our mailing list, they will receive emails that may
              include news, updates, promotional offers, etc. If at any time the
              User would like to unsubscribe from receiving future emails, we
              include detailed unsubscribe instructions at the bottom of each
              email or User may contact us via our Site. Emails will be
              encrypted but will be used for communication between website/app
              users without being revealed to the other party for the privacy of
              both parties.
            </div>

            <div className="pt-4 pb-4">SECURITY</div>
            <div className="pt-2 pb-2">
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site will be appropriately protected and destroyed
              if it is no longer in use. All data will never be shared by the
              third party it is only for the purposes of the website/app
            </div>

            <div className="pt-4 pb-4">WEB BROWSER COOKIES</div>
            <div className="pt-2 pb-2">
              Our Site may use "cookies" to enhance User experience. User's web
              browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. User may
              choose to set their web browser to refuse cookies or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly. Cookies help
              worldwideadverts.info in its functionality and offering a better
              service to its users.
            </div>

            <div className="pt-4 pb-4">THIRD PARTY WEBSITES</div>
            <div className="pt-2 pb-2">
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, advertisers, sponsors,
              licensors and other third parties. We do not control the content
              or links that appear on these sites and are not responsible for
              the practices employed by websites linked to or from our Site. In
              addition, these sites or services, including their content and
              links, may be constantly changing. These sites and services may
              have their own privacy policies and customer service policies.
              Browsing and interaction on any other website, including websites
              which have a link to our Site, is subject to that website's own
              terms and policies. We will not be responsible for the use of
              other links except that which on worldwideadverts.info.
            </div>

            <div className="pt-4 pb-4">CHANGES TO THIS PRIVACY POLICY</div>
            <div className="pt-2 pb-2">
              We have the discretion to update this privacy policy at any time.
              When we do, post a notification on the main page of our Site,
              revise the updated date at the bottom of this page. We encourage
              Users to frequently check this page for any changes to stay
              informed about how we are helping to protect the personal
              information we collect. You acknowledge and agree that it is your
              responsibility to review this privacy policy periodically and
              become aware of modifications.
            </div>

            <div className="pt-4 pb-4">ACCEPTANCE OF THE TERMS</div>
            <div className="pt-2 pb-2">
              By using this Site, you signify your acceptance of the website
              worldwideadverts.info, World Wide Adverts privacy policy. If you
              do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes. The users
              will be notified of the policy when they visit or use the
              website/app.
            </div>

            <div className="pt-4 pb-4">Full User Cookie Notice</div>
            <div className="pt-2 pb-2">
              What are cookies, web beacons, and similar technologies?
            </div>
            <div className="pt-2 pb-2">
              Like most sites, we use technologies that are essentially small
              data files placed on your computer, tablet, mobile phone, or other
              devices (referred to collectively as a "device") that allow us to
              record certain pieces of information whenever you visit or
              interact with our sites, services, applications, messaging, and
              tools, and to recognise you across devices.
            </div>
            <div className="pt-2 pb-2">
              The specific names and types of the cookies, web beacons, unique
              identifiers and similar technologies we use to collect information
              (e.g. about the pages you view, the links you click, and other
              actions you take on our Services, within our advertising or email
              content) may change from time to time. In order to help you better
              understand this Policy and our use of such technologies we have
              provided the following limited terminology and definitions:
            </div>
            <div className="pt-2 pb-2">
              Cookies – Small text files (typically made up of letters and
              numbers) placed in the memory of your browser or device when you
              visit a website or view a message. Cookies allow a website to
              recognise a particular device or browser. There are several types
              of cookies:
            </div>
            <div className="pt-2 pb-2">
              Session cookies expire at the end of your browser session and
              allow us to link your actions during that particular browser
              session.
            </div>
            <div className="pt-2 pb-2">
              Persistent cookies are stored on your device in between browser
              sessions, allowing us to remember your preferences or actions
              across multiple sites.
            </div>
            <div className="pt-2 pb-2">
              First-party cookies are set by the site you are visiting.
            </div>
            <div className="pt-2 pb-2">
              Third-party cookies are set by a third party site separate from
              the site you are visiting.
            </div>
            <div className="pt-2 pb-2">
              Cookies can be disabled or removed by tools that are available in
              most commercial browsers. The preferences for each browser you use
              will need to be set separately and different browsers offer
              different functionality and options.
            </div>
            <div className="pt-2 pb-2">
              Web beacons – Small graphic images (also known as "pixel tags" or
              "clear GIFs") that may be included on our sites, services,
              applications, messaging, and tools, that typically work in
              conjunction with cookies to identify our users and user behaviour.
            </div>
            <div className="pt-2 pb-2">
              Similar technologies – Technologies that store information in your
              browser or device utilising local shared objects or local storage,
              such as flash cookies, HTML 5 cookies, and other web application
              software methods. These technologies can operate across all of
              your browsers, and in some instances may not be fully managed by
              your browser and may require management directly through your
              installed applications or device. We do not use these technologies
              for storing information to target advertising to you on or off our
              sites.
            </div>
            <div className="pt-2 pb-2">
              We may use the terms "cookies" or "similar technologies"
              interchangeably in our policies to refer to all technologies that
              we may use to store data in your browser or device or that collect
              information or help us identify you in the manner described above.
            </div>
            <div className="pt-2 pb-2">
              Your choice and our use of cookies, web beacons, and similar
              technologies
            </div>
            <div className="pt-2 pb-2">
              We offer certain site features, services, applications, and tools
              that are available only through the use of these technologies.
              You're always free to block, delete, or disable these technologies
              if your browser, installed application, or device so permits.
              However, if you decline cookies or other similar technologies, you
              may not be able to take advantage of certain site features,
              services, applications, or tools. You may also be required to
              re-enter your password more frequently during your browsing
              session. For more information on how you can block, delete, or
              disable these technologies, please review your browser or device
              settings.
            </div>
            <div className="pt-2 pb-2">
              Generally, these technologies allow our sites, services,
              applications, and tools to store relevant information in your
              browser or device and later read that information in order to
              identify you to our servers or internal systems. Where applicable,
              we protect our cookies and other similar technologies to help
              ensure that only we and/or our authorised service providers can
              interpret them by assigning them a unique identifier that is
              designed for interpretation only by us. We don't store any of your
              personal information on any of our cookies or other similar
              technologies.
            </div>
            <div className="pt-2 pb-2">
              Any personal information that we collect and store through use of
              these technologies is first obtained through notice and consent:
              We obtain your consent by providing you with transparent notice of
              use of the technologies and, to the extent described herein,
              providing you with the opportunity to make a choice to disable
              these technologies.
            </div>
            <div className="pt-2 pb-2">
              Our uses of such technologies fall into the following general
              categories:
            </div>
            <div className="pt-2 pb-2">
              Operationally Necessary. We may use cookies, web beacons, or other
              similar technologies that are necessary to the operation of our
              sites, services, applications, and tools. This includes
              technologies that allow you access to our sites, services,
              applications, and tools, that are required to identify irregular
              site behaviour, prevent fraudulent activity and improve security,
              or that allow you to make use of our functions such as shopping
              carts, saved search, or similar functions;{" "}
            </div>
            <div className="pt-2 pb-2">
              Performance-Related. We may use cookies, web beacons, or other
              similar technologies to assess the performance of our websites,
              applications, services, and tools, including as part of our
              analytic practices to help us understand how our visitors use our
              websites, determine if you have interacted with our messaging,
              determine whether you have viewed an item or link, or to improve
              our website content, applications, services, or tools;
            </div>
            <div className="pt-2 pb-2">
              Functionality-Related. We may use cookies, web beacons, or other
              similar technologies that allow us to offer you enhanced
              functionality when accessing or using our sites, services,
              applications, or tools. This may include identifying you when you
              sign into our sites or keeping track of your specified
              preferences, interests, or past items viewed so that we may
              enhance the presentation of content on our sites;
            </div>
            <div className="pt-2 pb-2">
              Advertising or Targeting-Related. We may use first-party or
              third-party cookies and web beacons to deliver content, including
              ads relevant to your interests, on our sites or on third party
              sites. This includes using technologies to understand the
              usefulness to you of the advertisements and content that has been
              delivered to you, such as whether you have clicked on an
              advertisement.
            </div>
            <div className="pt-2 pb-2">
              We offer you the ability to opt-out of our use of first-party
              advertising-related cookies through our Ad Choice program. To
              opt-out of the use of third-party advertising-related cookies and
              web beacons, see the section Ad Networks and Exchanges authorised
              by third-parties below. Opting out of our first-party
              advertising-related cookies, or out of third-party
              advertising-related cookies and web beacons as described below,
              does not necessarily mean that you will not see our advertising -
              only that this advertising will not be targeted to you through the
              use of first-party or third-party cookies, web beacons or related
              technologies.
            </div>
            <div className="pt-2 pb-2">
              If you would like to opt-out of all other types of technologies we
              employ on our sites, services, applications, or tools, you may do
              so by blocking, deleting, or disabling them as your browser or
              device permits.
            </div>
            <div className="pt-2 pb-2">
              Use of these technologies by authorised third-party service
              providers
            </div>
            <div className="pt-2 pb-2">
              We may work with third-party companies, commonly known as service
              providers, who are authorised to place third-party cookies, web
              beacons, or similar technologies for storing information on our
              sites or in our services, applications, and tools with our
              permission. These service providers help us to provide you with a
              better, faster, and safer experience.
            </div>
            <div className="pt-2 pb-2">
              These service providers may use these technologies to help us
              deliver content and advertising, and compile anonymous site
              metrics and analytics. These service providers are subject to
              confidentiality agreements with us and other legal restrictions on
              their use or collection of any personal information. Third-party
              cookies are covered by the third parties' privacy policy.
            </div>
            <div className="pt-2 pb-2">
              You may encounter cookies and tracking technologies from our
              third-party service providers, including but not limited to (1)
              Facebook to provide advertising services through Facebook’s
              advertising and marketing platforms (e.g., Audience Network,
              Website Custom Audiences) and other social networking plug-ins or
              marketing tools, and (2) Google to provide advertising services
              through Google’s advertising and marketing platforms (e.g.,
              DoubleClick for Publishers, and the Google DoubleClick AdX
              Service, Adwords). Facebook and Google will use the personal
              information that they have collected about you on our sites
              pursuant to their own privacy policies (Facebook Data Policy:
              https://www.facebook.com/privacy/explanation and Google Privacy
              Policy: https://www.google.com/intl/en/policies/privacy/).
            </div>
            <div className="pt-2 pb-2">
              With the exception of the use of such technologies by our service
              providers or other authorised third parties, we do not permit any
              third-party content on sites (such as item listings,
              member-to-member communications, classified listings, comments,
              reviews, etc.) to include or utilise any cookies, web beacons,
              local storage, or similar technologies for tracking purposes or to
              collect your personal information for their own purposes. If you
              believe a listing or other third-party content might be collecting
              personal information or using tracking technologies on one of our
              sites, please report it to worldwideadverts@gmail.com.
            </div>
            <div className="pt-2 pb-2">
              Ad networks and exchanges operated by authorised third parties
            </div>
            <div className="pt-2 pb-2">
              We may use third parties, such as advertising networks and
              exchanges, to allow us to serve you advertisements. These
              third-party ad networks and exchange providers may use third-party
              cookies, web beacons, or similar technologies to collect
              information. They may also collect your device identifier, IP
              address, or identifier for advertising (IDFA). The information
              that these third parties collect may be used to assist us in
              providing you with more relevant advertising that we serve on our
              sites or elsewhere on the web, and as described above. Third-party
              cookies are covered by the third parties' privacy policy.
            </div>
            <div className="pt-2 pb-2">
              For more information on third-party advertising-related cookies
              and how to opt-out of them, please visit one of the following
              third-party websites:
            </div>
            <div className="pt-2 pb-2">Your Online Choices</div>
            <div className="pt-2 pb-2">
              The Self-Regulatory Program for Online Behavioral Advertising
              (available in English only)
            </div>
            <div className="pt-2 pb-2">
              Network Advertising Initiative Consumer Opt-Out (available in
              English only)
            </div>

            <div className="pt-4 pb-4">CONTACT US</div>
            <div className="pt-2 pb-2">
              If you have any questions about this Privacy Policy, the practices
              of this site, or your dealings with this site, please contact us
              at worldwideadvertsinfo@gmail.com.
            </div>

            <div className="pt-4 pb-4">Last Updated: April 26, 2018</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
