import React from 'react'
import Navbar from '../Navbar'
import FooterBanner from '../FooterBanner'
import Footer from '../Footer'

function DataProtection() {
  return (
    <div>
    <Navbar />
    <FooterBanner title={'DATA PROTECTION'} />
    <div className="w-full pt-10 flex justify-center">
      <div className="w-10/12">
        {/* <div className="w-full flex justify-center text-center">
          <div className="text-4xl font-bold pt-2 pb-4 text-blue-950">
          DATA PROTECTION
          </div>
        </div>
        <div className="bg-blue-400">
          <hr className="border border-blue-600"></hr>
        </div> */}
        <div className="">
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Privacy Policy of the  Application</div>
              <div className="pt-2 pb-2">The worldwideadverts.info website and its mobile applications may collect some Personal Data from its Users to allow efficient functionality.User information will not be distributed to any third party unless the data is required for functionality.Emails/mobile number will be used as points of contact between buyer and seller for communication depending on the chosen option, other details may be used for geo positioning or location to allow localized items to appear first in your search</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Data Controller and Owner</div>
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Data controller and owner are the application admins who control and manage all compiled user data to ensure privacy laws are respected and adhered to at all levels.</div>
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Types of Data collected</div>
              <div className="pt-2 pb-2">Among the types of Personal Data that world wide adverts application collects, by itself or through third parties, there are the Geographic positioning, Cookie and Usage Data. Other Personal Data collected may be described in other sections of this privacy policy or by dedicated explanation text contextually with the Data collection. The Personal Data may be freely provided by the User or collected automatically when using this Application. Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party services used by this Application, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User. Failure to provide certain Personal Data may make it impossible for this Application to provide its services. The User assumes responsibility for the Personal Data of third parties published or shared through this Application and declares to have the right to communicate or broadcast them, thus relieving the Data Controller of all responsibility.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Mode and place of processing the Data</div>
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Methods of processing</div>
              <div className="pt-2 pb-2">The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data. Data processing is carried out using computers and/or IT-enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Place</div>
              <div className="pt-2 pb-2">The Data is processed at the Data Controller's cloud, servers and in any other places where the parties involved with the processing are located. Should further information be required, please contact the Data Controller/admin.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Retention time</div>
              <div className="pt-2 pb-2">The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">The use of the collected Data</div>
              <div className="pt-2 pb-2">The Data concerning the User is collected to allow the Application to provide its services, as well as for the following purposes: Access to third party services' accounts, Location-based interactions, Content commenting and Interaction with external social networks and platforms. The Personal Data used for each purpose is outlined in the specific sections of this document.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Social Media permissions asked by the Application</div>
              <div className="pt-2 pb-2">This Application may ask some social media such as Facebook, Twitter, LinkedIn permissions allowing it to perform actions with the User's social media account and to retrieve information, including Personal Data, from it. For more information about the following permissions, refer to the various social media permissions documentations and to their privacy policies. The permissions asked are the following:</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Basic information</div>
              <div className="pt-2 pb-2">By default, this includes certain User’s Data such as id, name, picture, gender, and their locale. The application will ask for access to the camera and photos. Certain connections of the User, such as the Friends, are also available. If the user has made more of their data public, more information will be available.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Check-ins</div>
              <div className="pt-2 pb-2"> Access to the authorized user's check-ins or locations may be requested to allow posting on the application</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Email</div>
              <div className="pt-2 pb-2">Email registration of the user's primary email address will be a requirement to allow the functionality of the application and communication between the users and the server/cloud. It will also be used for recovery for login details and security of the users and their data.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Publishing  Activity</div>
              <div className="pt-2 pb-2">Allows the app to publish to the Open Graph using Built-in Actions to allow posting details on the application. The app can also publish other activity which is detailed in the various social media Publishing Permissions document. The website/app uses other social media to increase the exposure of posts.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Detailed information on the processing of Personal Data</div>
              <div className="pt-2 pb-2">Personal Data is collected for the following purposes and using the following services:</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Access to third-party services' accounts</div>
              <div className="pt-2 pb-2">These services allow this Application to access Data from your account on a third-party service and perform actions with it. These services are not activated automatically, but require explicit authorization by the User.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Access to social media account you may choose to use as a login option  (This Application)</div>
              <div className="pt-2 pb-2">This service allows this Application to connect with the User's account on the social network/s, provided by the chosen social Media and its Incorporation. Permissions asked: Check-ins, Email, Photos and Publish App Activity are all used for the efficiency of the application.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Content commenting</div>
              <div className="pt-2 pb-2">Content commenting services allow Users to make and publish their comments on the contents of this Application. Depending on the settings chosen by the Owner, Users may also leave anonymous comments. If there is an email address among the Personal Data provided by the User, it may be used to send notifications of comments on the same content. Users are responsible for the content of their own comments. If a content commenting service provided by third parties is installed, it may still collect web traffic data for the comment service is installed, even when users do not use the content commenting service.
 Comments is a content commenting service provided for the purposes enabling the User to leave comments and share them on the platform. Personal Data collected: Cookie and Usage Data.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Interaction with external social networks and platforms</div>
              <div className="pt-2 pb-2">These services allow interaction with social networks or other external platforms directly from the pages of this Application. The interaction and information obtained by this Application are always subject to the User’s privacy settings for each social network. If a service enabling interaction with social networks is installed it may still collect traffic data for the pages where the service is installed, even when Users do not use it. Social widgets are services allowing interaction with social networks provided by social media. Personal Data collected: Cookie and Usage Data. Place of processing: USA – Privacy Policy</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Location-based interactions</div>
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Geolocation (This Application) (Beacon technology)</div>
              <div className="pt-2 pb-2">This Application may collect, use, and share User location Data in order to provide location-based services. Most browsers and devices provide tools to opt out from this feature by default. If explicit authorization has been provided, the User’s location data may be tracked by this Application. Personal Data collected: Geographic position.These methods are basically used to allow easier linking with nearby users as well as location of the posting user.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Additional information about Data collection and processing</div>
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Legal Action</div>
              <div className="pt-2 pb-2">The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action arising from improper use of this Application or the related services.
The User is aware of the fact that the Data Controller may be required to reveal personal data upon request of public authorities.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Additional information about User's Personal Data</div>
              <div className="pt-2 pb-2">In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular services or the collection and processing of Personal Data upon request.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">System Logs and Maintenance</div>
              <div className="pt-2 pb-2">For operation and maintenance purposes, this Application and any third party services may collect files that record interaction with this Application (System Logs) or use for this purpose other Personal Data (such as IP Address).</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Information not contained in this policy</div>
              <div className="pt-2 pb-2">More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time. Please see the contact information at the beginning of this document.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">The rights of Users</div>
              <div className="pt-2 pb-2">Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about their contents and origin, to verify their accuracy or to ask for them to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the Data Controller at the contact information set out above.
This Application does not support “Do Not Track” requests.
To determine whether any of the third party services it uses honor the “Do Not Track” requests, please read their privacy policies.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Changes to this privacy policy</div>
              <div className="pt-2 pb-2">The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any of the changes to the Policy, the User must cease using this Application and can request that the Data Controller erase the Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Data Controller has about Users.

</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Definitions and legal references</div>
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Personal Data (or Data)</div>
              <div className="pt-2 pb-2">Any information regarding a natural person, a legal person, an institution or an association, which is, or can be, identified, even indirectly, by reference to any other information, including a personal identification number.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Usage Data</div>
              <div className="pt-2 pb-2">Information collected automatically from this Application (or third party services employed in this Application ), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">User</div>
              <div className="pt-2 pb-2">The individual using this Application, which must coincide with or be authorized by the Data Subject, to whom the Personal Data refer.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Data Subject</div>
              <div className="pt-2 pb-2">The legal or natural person to whom the Personal Data refers to.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Data Processor (or Data Supervisor)</div>
              <div className="pt-2 pb-2">The natural person, legal person, public administration or any other body, association or organization authorized by the Data Controller to process the Personal Data in compliance with this privacy policy.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Data Controller (or Owner)</div>
              <div className="pt-2 pb-2">The natural person, legal person, public administration or any other body, association or organization with the right, also jointly with another Data Controller, to make decisions regarding the purposes, and the methods of processing of Personal Data and the means used, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">This Application</div>
              <div className="pt-2 pb-2">The hardware or software tool by which the Personal Data of the User is collected.</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Cookie</div>
              <div className="pt-2 pb-2"> Minor data stored in the User's device essential in the efficiency of the applications' functionality </div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Legal information</div>
              <div className="pt-2 pb-2">Notice to European Users: this privacy statement has been prepared in fulfilment of the obligations under Art. 10 of EC Directive n. 95/46/EC, and under the provisions of Directive 2002/58/EC, as revised by Directive 2009/136/EC, on the subject of Cookies. This privacy policy relates solely to this Application. Data protection is provided according to the European Data protection GDPR 2018</div>             
              <div className="text-lg font-bold pt-4 pb-4 text-blue-900">Latest update: March 2018</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default DataProtection