import Api from "../api";

export default false
  ? {
      message: "You are Offline. Please! turn on the internet",
    }
  : { 
    getBooks: (skip,limit) => {
      return Api.get(
        `v1/book?skip=${skip}&limit=${limit}`
      );
    },
  }