import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clearAuthErrorAndMessage, signUp } from "../slice/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
function Signup(props) {
  const dispatch = useDispatch();
  const { loading, authMessage } = useSelector(
    (state) => state.auth
  );

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.first_name.trim() === "" ||
      formData.last_name.trim() === "" ||
      formData.email.trim() === "" ||
      formData.password.trim() === "" ||
      formData.password_confirmation.trim() === ""
    ) {
      alert("All fields are required");
      return;
    }
    try {
      await dispatch(signUp({ formData })).unwrap()
      props?.showSignInForm()
    } catch (error) {
      toast.error(error.message)
    }
  };

  useEffect(() => {
    dispatch(clearAuthErrorAndMessage());
  }, [dispatch]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-10/12">
        <div className="text-center pt-8 pb-8">
          <div className="text-4xl font-bold pb-5">Register</div>
          <div>Setup a new account in a minute.</div>
        </div>

        <div className="w-full h-56 flex flex-col justify-between">
          <div className="flex w-full gap-8">
            <div className="w-1/2">
              <input
                type="text"
                name="first_name"
                placeholder="First Name"
                className="w-full h-12 border-b-2 border-slate-300 focus:outline-none focus:border-blue-700"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-1/2">
              <input
                type="text"
                name="last_name"
                placeholder="Last Name"
                className="w-full h-12 border-b-2 border-slate-300 focus:outline-none focus:border-blue-700"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="w-full">
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="w-full h-12 border-b-2 border-slate-300 focus:outline-none focus:border-blue-700"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-full">
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="w-full h-12 border-b-2 border-slate-300 focus:outline-none focus:border-blue-700"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="w-full">
            <input
              type="password"
              name="password_confirmation"
              placeholder="Repeat Password"
              className="w-full h-12 border-b-2 border-slate-300 focus:outline-none focus:border-blue-700"
              value={formData.password_confirmation}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="flex justify-between pt-4 pb-4">
          <div className="font-medium">
            <input type="checkbox" className="" required />
            <span className="pl-2">
              I agree to the all <Link to="/help/terms-and-condition">terms & conditions</Link>.
            </span>
          </div>
        </div>

        <div className="w-full pb-8">
          <button
            onClick={handleSubmit}
            className="text-md font-semibold w-full rounded-lg bg-blue-500 text-white hover:bg-blue-600 pt-3 pb-3"
            disabled={loading}
          >
            {loading ? "Creating Account..." : "CREATE NEW ACCOUNT"}
          </button>
        </div>
        {authMessage && <div className="text-green-600">{authMessage}</div>}

        <div className="w-full flex justify-center text-center">
          <div className="w-full mx-10 text-lg font-medium text-gray-500">
            Already have an account? <br /> click on the{" "}
            <span className="text-blue-600">
              <Link onClick={props.showSignInForm}>( sign in ) </Link>
            </span>
            button above.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
